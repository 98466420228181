import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { circularProgress } from "../main";
import { completeTakeover } from "../data/webUtils";
import { useFirebasePreUser } from "../context/useFirebasePreUser";

export default function FinishEmailSignIn() {
  const navigate = useNavigate();
  const firebase = useFirebasePreUser();
  const [params] = useSearchParams();
  const email = params.get("email");
  const takeoverCode = params.get("takeoverCode");
  const thenNavigate = params.get("thenNavigate") || "/";
  const [handled, setHandled] = useState(false);

  useEffect(() => {
    if (handled || !firebase || !email || !takeoverCode) {
      return;
    }
    // should be a one time afair no matter what
    setHandled(true);
    (async () => {
      const { isSignInWithEmailLink, signInWithEmailLink } = firebase.authPackage;
      if (!isSignInWithEmailLink(firebase.auth, window.location.href)) {
        navigate(thenNavigate);
        return;
      }

      try {
        await signInWithEmailLink(firebase.auth, email, window.location.href);
      } catch (err: any) {
        if ("code" in err && err.code === "auth/invalid-action-code") {
          alert(
            "Invalid or expired link. This often happens when you click on a link from an old email. Please check for a newer email from us or try to sign in again."
          );
          navigate(thenNavigate);
          return;
        }
        throw err;
      }
      await completeTakeover({ takeoverCode, firebase });
      navigate(thenNavigate);
    })().catch(alert);
  }, [handled, thenNavigate, email, firebase, navigate, takeoverCode]);

  if (!takeoverCode || !email) {
    return <div>Missing data</div>;
  }

  return circularProgress();
}
