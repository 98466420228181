import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { PickemEntryData } from "@sportsball/shared";

import AvatarIcon from "../avatar/AvatarIcon";
import { maybeUserUid, useUser } from "../context/useUser";

export default function EntryLine({
  score,
  entry: { avatar, screenName, questionWinProbability },
  entryUid,
  gameId,
  gameOn,
}: {
  score: number | undefined;
  entry: PickemEntryData;
  entryUid: string;
  gameId: string;
  gameOn: boolean;
}) {
  const navigate = useNavigate();
  const { user } = useUser();
  const uid = maybeUserUid(user);
  const clickable = gameOn || entryUid === uid;

  const handleClick = clickable ? () => navigate(`/games/${gameId}/entries/${entryUid}`) : undefined;

  const ownEntry = entryUid === uid;
  return (
    <Stack
      direction="row"
      spacing={2}
      p={1}
      color={ownEntry ? "white" : "black"}
      bgcolor={ownEntry ? "blue" : "white"}
      alignItems="center"
      width="100%"
      onClick={handleClick}
      sx={{ borderRadius: 2, cursor: clickable ? "pointer" : "default" }}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        <AvatarIcon avatarData={avatar} size="medium" />
        {questionWinProbability !== undefined && (
          <Typography fontSize="xs">{Math.round(questionWinProbability * 100)}%</Typography>
        )}
      </Stack>
      <Box flexGrow={1}>
        <h2>{screenName}</h2>
      </Box>
      {gameOn && score !== undefined && <h2>{score}</h2>}
    </Stack>
  );
}
