import { origin } from "./main";

import { ProdFirebaseConfig, StageFirebaseConfig } from "@sportsball/shared";

/**
 * Settings to allow for dev backend configuration. These
 * settings are safe to check into git in any position.
 */
const _devFirebaseConfig = StageFirebaseConfig;
const _devUsingEmulator = true;

const _origin = origin();
export const devUsingEmulator = true;
export const firebaseConfig = {
  prod: ProdFirebaseConfig,
  stage: StageFirebaseConfig,
  localhost: _devFirebaseConfig,
}[_origin];

if (_origin === "localhost" && _devUsingEmulator) {
  const x = globalThis as unknown as {
    __FIREBASE_DEFAULTS__: { emulatorHosts: { database: string; firestore: string; functions: string } };
  };
  x.__FIREBASE_DEFAULTS__ = {
    emulatorHosts: { database: "localhost:9000", firestore: "localhost:8080", functions: "localhost:5001" },
  };
}
