import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";

import {
  CurrentOdds,
  GameType,
  QuestionType,
  FootballGame,
  ScheduledGame,
  LeagueWeek,
  PickemQuestion,
} from "@sportsball/shared";
import { getFilteredGames, getLeagueForTeamSlate, TeamSlate } from "@sportsball/shared";

import NewGame, { NewPickemGameData } from "./NewGame";
import PickemSheet from "../components/PickemSheet";
import { SlateGamesPicker } from "../components/SlateGamesPicker";
import { useUser } from "../context/useUser";
import { useLoggedInDatabaseObject } from "../hooks/loggedInObjectHook";
import { calculateMoneylineScoring } from "../data/pickemScoring";

type PickemGameType = PickemQuestion["type"];

export default function NewPickemGame() {
  const { firebase } = useUser();
  const { teamSlate: jumpToTeamSlate } = useParams() as { teamSlate?: TeamSlate };
  const [teamSlate, setTeamSlate] = useState(jumpToTeamSlate || TeamSlate.NFL);
  const [week, setWeek] = useState<string | undefined>();
  const [pickemGameType, setPickemGameType] = useState<PickemGameType>("VsSpreadPick");
  const [noTiebreaker, setNoTiebreaker] = useState(false);
  const [slateWeekGames, setSlateWeekGames] = useState<ScheduledGame[]>([]);
  const [removedFootballGames, setRemovedFootballGames] = useState(new Set<string>());
  // if the football game id is missing the value is 1.0
  const [favoriteMoneylinePoints, setFavoriteMoneylinePoints] = useState<Record<string, number>>({});

  const { object: currentOdds } = useLoggedInDatabaseObject("odds/current", CurrentOdds);

  let newGameData: NewPickemGameData | undefined;
  let workingGameData: NewPickemGameData | undefined;

  function buildPickemQuestion(scheduledGame: ScheduledGame): PickemQuestion {
    const footballGameId = scheduledGame.id;
    const { vegasOdds, spread } = currentOdds?.[footballGameId] ?? {};
    if (pickemGameType === "StraightUpPick" || !vegasOdds) {
      return {
        type: QuestionType.enum.StraightUpPick,
        footballGameId,
      };
    }
    if (pickemGameType === "VsSpreadPick") {
      return {
        type: QuestionType.enum.VsSpreadPick,
        footballGameId,
        vegasOdds,
        vsSpread: spread ?? "OFF",
      };
    }
    return {
      type: QuestionType.enum.MoneylinePick,
      footballGameId,
      vegasOdds,
      moneylinePoints: calculateMoneylineScoring(vegasOdds, {
        favoritePoints: favoriteMoneylinePoints[footballGameId] ?? 1.0,
      }),
    };
  }

  function buildGameData(weekGames: ScheduledGame[]): NewPickemGameData {
    // this startTimestamp is going away so we just need a value.
    const startTimestamp = weekGames.length > 0 ? weekGames[0].startTimestamp : 0;
    const questions = weekGames.map((scheduledGame) => buildPickemQuestion(scheduledGame));
    // add status to all the scheduled games to make startup FootballGames from ScheduledGames
    const footballGames: Record<string, FootballGame> = Object.fromEntries(
      weekGames.map((scheduledGame) => [scheduledGame.id, { ...scheduledGame, status: "NS" }])
    );
    const tiebreakerId = noTiebreaker ? undefined : questions[questions.length - 1].footballGameId;

    return {
      type: GameType.enum.Pickem,
      questions,
      ...(tiebreakerId && { tiebreakerId }),
      startTimestamp,
      footballGames,
    };
  }

  if (slateWeekGames && slateWeekGames.length > 0 && currentOdds) {
    // workingGameData is the full slate but the newGameData, which is the data that is
    // used to create the game, is the data with the football games removed that the user
    // has selected to remove
    workingGameData = buildGameData(slateWeekGames);
    const postRemovalWeekGames = slateWeekGames.filter((game) => !removedFootballGames.has(game.id));
    newGameData = buildGameData(postRemovalWeekGames);
  }

  // when we have a single focused game we dont use the picker and we need to load the week data
  useEffect(() => {
    if (!firebase || !jumpToTeamSlate) {
      return;
    }
    const league = getLeagueForTeamSlate(jumpToTeamSlate);
    const { onValue, ref } = firebase.databasePackage;
    const leagueWeeksRef = ref(firebase.database, `league-weeks/${league}`);
    return onValue(leagueWeeksRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const newLeagueWeeks = z.array(LeagueWeek).parse(snapshot.val());
      for (const { games } of newLeagueWeeks) {
        const focusedSlateWeekGames = getFilteredGames(games, jumpToTeamSlate);
        if (focusedSlateWeekGames.length > 0) {
          setSlateWeekGames(focusedSlateWeekGames);
          return;
        }
      }
    });
  }, [firebase, jumpToTeamSlate]);

  function toggleFootballGames(footballGameIds: string[]) {
    if (footballGameIds.length === 0) {
      return;
    }
    const newRemovedFootballGames = new Set(removedFootballGames);
    for (const footballGameId of footballGameIds) {
      if (newRemovedFootballGames.has(footballGameId)) {
        newRemovedFootballGames.delete(footballGameId);
      } else {
        newRemovedFootballGames.add(footballGameId);
      }
    }
    setRemovedFootballGames(newRemovedFootballGames);
  }
  const singleGameFocused = Boolean(jumpToTeamSlate);

  function tweakMoneylinePoints(footballGameId: string, points: number) {
    const newFavoriteMoneylinePoints = { ...favoriteMoneylinePoints };
    const oldPoints = newFavoriteMoneylinePoints[footballGameId] ?? 1.0;
    const newPoints = Math.max(1.0, Math.min(10.0, oldPoints + points));
    newFavoriteMoneylinePoints[footballGameId] = newPoints;
    setFavoriteMoneylinePoints(newFavoriteMoneylinePoints);
  }

  return (
    <NewGame type={GameType.enum.Pickem} newGameData={newGameData} singleGameFocused={singleGameFocused}>
      <Stack spacing={2} p={2} sx={{ borderRadius: 4, bgcolor: "white" }}>
        <SlateGamesPicker
          show={!singleGameFocused}
          title="Choose Pick'em Pool Slate"
          description="Pick'em slates provide the list of games and spreads all NFL and NCAA matchups. These spreads do not change once the pick'em slate is chosen for the game."
          teamSlate={teamSlate}
          setTeamSlate={setTeamSlate}
          week={week}
          setWeek={setWeek}
          slateWeekGames={slateWeekGames}
          setSlateWeekGames={setSlateWeekGames}
        />
      </Stack>
      <h2>Choose the pick&apos;em game type</h2>
      <p>
        For easy predictions, choose straight-up winners. For a more challenging game, choose wins against the spread.
      </p>
      <Box display="flex" justifyContent="center">
        <ButtonGroup variant="contained" aria-label="Game Type">
          <Button
            variant={pickemGameType === "MoneylinePick" ? "contained" : "outlined"}
            onClick={() => setPickemGameType("MoneylinePick")}
          >
            Money Line
          </Button>
          <Button
            variant={pickemGameType === "VsSpreadPick" ? "contained" : "outlined"}
            onClick={() => setPickemGameType("VsSpreadPick")}
          >
            Against the Spread
          </Button>
          <Button
            variant={pickemGameType === "StraightUpPick" ? "contained" : "outlined"}
            onClick={() => setPickemGameType("StraightUpPick")}
          >
            Straight-Up
          </Button>
        </ButtonGroup>
      </Box>
      <p>Would you like to include an over/under tiebreaker questions?</p>
      <Box display="flex" justifyContent="center">
        <ButtonGroup variant="contained" aria-label="Game Type">
          <Button variant={!noTiebreaker ? "contained" : "outlined"} onClick={() => setNoTiebreaker(false)}>
            Include Tiebreaker
          </Button>
          <Button variant={noTiebreaker ? "contained" : "outlined"} onClick={() => setNoTiebreaker(true)}>
            No Tiebreaker
          </Button>
        </ButtonGroup>
      </Box>
      <h2>Slate Preview</h2>
      {workingGameData && (
        <PickemSheet
          game={workingGameData}
          removedFootballGames={removedFootballGames}
          tweakMoneylinePoints={tweakMoneylinePoints}
          toggleFootballGames={toggleFootballGames}
        />
      )}
    </NewGame>
  );
}
