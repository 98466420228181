import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./theme";

import "./main.css";
import App from "./App";
import FirebaseProvider from "./context/FirebaseProvider";
import UserProvider from "./context/UserProvider";
import SpecialsProvider from "./context/SpecialsProvider";
import ScrollToTop from "./context/ScrollToTop";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export function origin() {
  const { hostname } = window.location;
  switch (hostname) {
    case "localhost":
      return "localhost";

    case "sportsball-prod.web.app":
    case "sportsball-prod.firebaseapp.com":
      return "prod";

    default:
      if (hostname.endsWith("joinsportsball.com")) {
        return "prod";
      } else {
        return "stage";
      }
  }
}

export function circularProgress() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <CircularProgress size={120} />
    </Box>
  );
}

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== "test") {
  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <SpecialsProvider>
            <FirebaseProvider>
              <UserProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </UserProvider>
            </FirebaseProvider>
          </SpecialsProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  );
}
