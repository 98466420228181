import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GridOnIcon from "@mui/icons-material/GridOn";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { GameType } from "@sportsball/shared";

import { GameAndId } from "../MyGames";
import LeftRightButtons from "./LeftRightButtons";
import { useUserGames } from "../hooks/userGamesHook";
import { maybeUserUid, useUser } from "../context/useUser";

export default function GameTitle({
  gameAndId: { data: game, id: gameId },
  navButtons,
}: {
  gameAndId: GameAndId;
  navButtons?: true;
}) {
  const { user } = useUser();
  const uid = maybeUserUid(user);
  const { enteredGames, runningGames } = useUserGames();

  const gamesPlayingButNotRunning = (enteredGames ?? []).filter((gameAndId) => gameAndId.data.uid !== uid) ?? [];
  const navSlugs = !navButtons ? undefined : [...(runningGames ?? []), ...gamesPlayingButNotRunning].map((g) => g.id);

  const gameIcon = {
    [GameType.enum.Squares]: <GridOnIcon />,
    [GameType.enum.Pickem]: <CheckBoxIcon />,
  }[game.type];
  const gameTitle = {
    [GameType.enum.Squares]: "Sportsball Squares",
    [GameType.enum.Pickem]: "Sportsball Pick'em",
  }[game.type];

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        {gameIcon}
        <Typography fontFamily="Nico Moji" variant="h6" flexGrow={1}>
          {gameTitle}
        </Typography>
        {navSlugs && <LeftRightButtons slugs={navSlugs} currentSlug={gameId} slugToPath={(slug) => `/games/${slug}`} />}
      </Stack>
      {game.name && (
        <Typography fontFamily="Timmana" variant="h6">
          {game.name}
        </Typography>
      )}
    </>
  );
}
