import { useEffect, useState } from "react";

import deepEqual from "deep-equal";
import { z } from "zod";

import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { useUser } from "../context/useUser";
import { FootballLeague, LeagueWeek, ScheduledGame } from "@sportsball/shared";
import { getFilteredGames, TeamSlate, TeamSlateShortNames } from "@sportsball/shared";

export function SlateGamesPicker({
  show,
  title,
  description,
  teamSlate,
  setTeamSlate,
  week,
  setWeek,
  slateWeekGames,
  setSlateWeekGames,
}: {
  show: boolean;
  title: string;
  description: string;
  teamSlate: TeamSlate;
  // eslint-disable-next-line no-unused-vars
  setTeamSlate: (teamSlate: TeamSlate) => void;
  week: string | undefined;
  // eslint-disable-next-line no-unused-vars
  setWeek: (week: string | undefined) => void;
  slateWeekGames: ScheduledGame[];
  // eslint-disable-next-line no-unused-vars
  setSlateWeekGames: (slateWeekGames: ScheduledGame[]) => void;
}) {
  const { firebase } = useUser();
  const [leagueWeeks, setLeagueWeeks] = useState<LeagueWeek[] | undefined>();

  const league = teamSlate === TeamSlate.NFL ? FootballLeague.enum.NFL : FootballLeague.enum.NCAA;

  // load league weeks data. This only changes when the league changes
  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onValue, ref } = firebase.databasePackage;
    const leagueWeeksRef = ref(firebase.database, `league-weeks/${league}`);
    return onValue(leagueWeeksRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const newLeagueWeeks = z.array(LeagueWeek).parse(snapshot.val());
      setLeagueWeeks(newLeagueWeeks);
      // if we have an invalid week here we reset it to the first week
      if (!week || (newLeagueWeeks.length > 0 && !newLeagueWeeks.find((leagueWeek) => leagueWeek.week === week))) {
        setWeek(newLeagueWeeks[0]?.week);
      }
    });
  }, [firebase, league, week, setWeek]);

  // send out new slate week games when dependencies change
  useEffect(() => {
    if (!leagueWeeks) {
      return;
    }
    const leagueWeek = leagueWeeks.find((leagueWeek) => leagueWeek.week === week);
    const newSlateWeekGames = !leagueWeek ? [] : getFilteredGames(leagueWeek.games, teamSlate);
    if (!deepEqual(slateWeekGames, newSlateWeekGames)) {
      setSlateWeekGames(newSlateWeekGames);
    }
  }, [leagueWeeks, week, teamSlate, slateWeekGames, setSlateWeekGames]);

  if (!show) {
    return <div />;
  }
  const weekOptions = leagueWeeks?.map((leagueWeek) => leagueWeek.week);
  return (
    <>
      <h2>{title}</h2>
      <p>{description}</p>
      <Stack direction="row" spacing={2}>
        <TextField
          select
          id="TeamSlate"
          label="League/Conference"
          value={teamSlate ?? ""}
          fullWidth
          SelectProps={{
            MenuProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
          onChange={(e) => setTeamSlate(e.target.value as TeamSlate)}
        >
          {[...Object.values(TeamSlate)].map((ncaaGameFilter) => {
            return (
              <MenuItem key={ncaaGameFilter} value={ncaaGameFilter}>
                {TeamSlateShortNames[ncaaGameFilter]}
              </MenuItem>
            );
          })}
        </TextField>
        {weekOptions && weekOptions.length > 0 && (
          <TextField
            select
            id="Week"
            label="Week"
            value={week}
            fullWidth
            SelectProps={{
              MenuProps: {
                style: {
                  maxHeight: 400,
                },
              },
            }}
            onChange={(e) => setWeek(e.target.value)}
          >
            {weekOptions?.map((week) => {
              return (
                <MenuItem key={week} value={week}>
                  {week}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </Stack>
    </>
  );
}
