import { useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";

import { format } from "date-fns";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

import { Side } from "@sportsball/shared";
import { getGameStartTimestamp, isPickemGame } from "@sportsball/shared";

import EntryLine from "../components/EntryLine";
import LeftRightButtons from "../components/LeftRightButtons";
import PickemSheet from "../components/PickemSheet";
import { useUser, maybeUserUid } from "../context/useUser";
import ConfirmDialog from "../components/ConfirmDialog";
import { GameContainerProps } from "../components/GameContainer";
import { getScoringTiers, sortEntries } from "../data/scoringTiers";

export default function ShowEntry() {
  const navigate = useNavigate();
  const { game, gameId, gameOn } = useOutletContext<GameContainerProps>();
  if (!isPickemGame(game)) {
    throw new Error("Game is not a pickem game");
  }
  const { firebase, user } = useUser();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { entryUid } = useParams();
  if (!entryUid) {
    throw new Error("No arg");
  }

  const { actualAnswers, entries } = game;
  const entry = game.entries?.[entryUid];
  if (!entry) {
    return <div>Entry not found</div>;
  }
  const scoringStarted = "actualAnswers" in game;
  const scoringTiers = scoringStarted ? getScoringTiers(game) : undefined;
  const { score } = scoringTiers?.find((scoringTier) => entryUid in scoringTier.entries) ?? {
    score: undefined,
    entries: {},
  };
  let entrySlugs: string[] = [];
  if (!scoringTiers && entries && gameOn) {
    entrySlugs = sortEntries(entries).map(([entryUid]) => entryUid);
  } else if (scoringTiers) {
    for (const scoringTier of scoringTiers) {
      for (const [entryUid] of sortEntries(scoringTier.entries)) {
        entrySlugs.push(entryUid);
      }
    }
  }

  let gameState: "pregame" | "midgame" | "postgame" | undefined;
  const startTimestamp = getGameStartTimestamp(game);
  if (!startTimestamp) {
    gameState = "pregame"; // no start timestamp means no games
  } else if (Date.now() < startTimestamp) {
    gameState = "pregame";
  } else if (actualAnswers && Object.keys(actualAnswers).length === Object.keys(game.questions).length) {
    gameState = "postgame";
  } else {
    gameState = "midgame";
  }
  const uid = maybeUserUid(user);
  const myEntry = entryUid === uid;
  const editableEntry = myEntry && gameState === "pregame";

  const makePick =
    !editableEntry || !firebase
      ? undefined
      : ({ questionNumber, pick }: { questionNumber: number; pick: Side }) => {
          (async () => {
            const { doc, updateDoc } = firebase.firestorePackage;
            const gameRef = doc(firebase.firestore, `games/${gameId}`);
            await updateDoc(gameRef, { [`entries.${uid}.selectedAnswers.${questionNumber}`]: pick });
          })().catch(alert);
        };

  const setTiebreakerPoints =
    !editableEntry || !firebase
      ? undefined
      : (points: number) => {
          (async () => {
            const { doc, updateDoc } = firebase.firestorePackage;
            const gameRef = doc(firebase.firestore, `games/${gameId}`);
            await updateDoc(gameRef, { [`entries.${uid}.tiebreakerPoints`]: points });
          })().catch(alert);
        };

  function performDelete() {
    if (!firebase) {
      return;
    }
    (async () => {
      const { deleteField, doc, updateDoc } = firebase.firestorePackage;
      const gameRef = doc(firebase.firestore, `games/${gameId}`);
      await updateDoc(gameRef, { [`entries.${uid}`]: deleteField() });
      setShowDeleteDialog(false);
      navigate(`/games/${gameId}`);
    })().catch(alert);
  }

  const localeDate = !startTimestamp ? "-" : format(startTimestamp, "PP").split(",")[0];
  const localeTime = !startTimestamp ? "-" : format(startTimestamp, "p");
  return (
    <>
      <Stack spacing={2}>
        {game.name && <Link to="..">Back to game {game.name}</Link>}
        <Stack direction="row">
          <EntryLine entry={entry} entryUid={entryUid} score={score} gameId={gameId} gameOn={gameOn} />
          <LeftRightButtons
            slugs={entrySlugs}
            currentSlug={entryUid}
            slugToPath={(slug) => `/games/${gameId}/entries/${slug}`}
          />
        </Stack>
        <h3>Picks</h3>
        {editableEntry && (
          <p>
            Feel free to change your picks below before the first game starts at {localeTime} on {localeDate}.
          </p>
        )}
        {myEntry && gameState === "midgame" && <p>The games have begun! Good luck with your picks!</p>}
        {myEntry && gameState === "postgame" && <p>Game over.</p>}
        <Box alignItems="center">
          <PickemSheet
            game={game}
            picks={entry.selectedAnswers}
            results={game.actualAnswers}
            makePick={makePick}
            tiebreakerPoints={entry.tiebreakerPoints}
            setTiebreakerPoints={setTiebreakerPoints}
          />
        </Box>
        {editableEntry && (
          <Stack padding={4} spacing={2} direction="row" justifyContent="center">
            <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(`/games/${gameId}`)}>
              Back to game
            </Button>
            {editableEntry && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setShowDeleteDialog(true)}
              >
                DELETE ENTRY
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <ConfirmDialog
        prompt="Are you sure you want to delete your pick'em entry?"
        confirmText="Delete"
        cancelText="Cancel"
        open={showDeleteDialog}
        confirmFn={performDelete}
        closeDialog={() => setShowDeleteDialog(false)}
      />
    </>
  );
}
