import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { AvatarData, GameType, UserPrefs } from "@sportsball/shared";

import AvatarIcon, { AvatarSetSizes } from "../avatar/AvatarIcon";
import { userIsSignedIn, userUid, useUser } from "../context/useUser";

export default function UserPrefsDialog({ open, closeDialog }: { open: boolean; closeDialog: () => void }) {
  const { firebase, user } = useUser();
  const [avatarData, setAvatarData] = useState<AvatarData | undefined>();
  const [screenName, setScreenName] = useState<string | undefined>();
  const [initials, setInitials] = useState<string | undefined>();
  const signedInUid = user && userIsSignedIn(user) ? userUid(user) : undefined;

  useEffect(() => {
    if (!signedInUid || !firebase) {
      return;
    }
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userPrefsRef = doc(usersCollection, signedInUid);

    return onSnapshot(userPrefsRef, (snapshot) => {
      if (!snapshot.exists()) {
        setAvatarData(undefined);
        setScreenName("");
        setInitials("");
        return;
      }
      const results = UserPrefs.safeParse(snapshot.data());
      if (!results.success) {
        return;
      }

      setAvatarData(results.data.avatar);
      setScreenName(results.data.preferredScreenName);
      setInitials(results.data.preferredInitials);
    });
  }, [signedInUid, firebase]);

  function changeAvatar(amount: number) {
    if (!avatarData) {
      return;
    }
    const { set, id } = avatarData;
    const avatarSetSize = AvatarSetSizes[set] ?? 1;
    setAvatarData({ set, id: (id + amount) % avatarSetSize });
  }

  function onSubmit() {
    if (!avatarData || screenName === undefined || initials === undefined || !firebase || !user) {
      return;
    }
    (async () => {
      const prefs: UserPrefs = {
        avatar: avatarData,
        preferredScreenName: screenName,
        preferredInitials: initials,
      };
      const { collection, doc, getDocs, query, where, writeBatch } = firebase.firestorePackage;
      const usersCollection = collection(firebase.firestore, "users");

      const batch = writeBatch(firebase.firestore);
      batch.set(doc(usersCollection, signedInUid), prefs);

      if (user.prefs?.avatar) {
        const gamesCollection = collection(firebase.firestore, "games");
        const q = query(gamesCollection, where(`entries.${signedInUid}`, "!=", null));
        const gameSnapshots = await getDocs(q);
        gameSnapshots.forEach((gameSnapshot) => {
          const gameType = gameSnapshot.data()["type"] as GameType;
          if (gameType === GameType.enum.Squares) {
            return;
          }
          batch.update(doc(gamesCollection, gameSnapshot.id), { [`entries.${signedInUid}.avatar`]: avatarData });
        });
      }
      await batch.commit();
    })()
      .catch(alert)
      .finally(closeDialog);
  }

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle variant="h5">Your Settings</DialogTitle>
      <DialogContent>
        <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;&nbsp;Your avatar</Typography>
        <Stack spacing={2} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => changeAvatar(-1)}>
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
            <AvatarIcon avatarData={avatarData} size="large" />
            <IconButton onClick={() => changeAvatar(+1)}>
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          </Stack>
          <TextField
            label="Preferred Screen Name"
            value={screenName}
            onChange={(e) => setScreenName(e.target.value)}
          ></TextField>
          <TextField
            label="Preferred Initials"
            value={initials}
            onChange={(e) => setInitials(e.target.value)}
          ></TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button onClick={onSubmit}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
