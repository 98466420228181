import { useContext } from "react";
import { createContext } from "react";

import { LazyFirebase } from "./useFirebasePreUser";
import { User } from "./UserProvider";

export function userUid(user: User): string {
  return user.promotedUid || user.firebaseUid;
}

export function maybeUserUid(user: User | undefined): string | undefined {
  if (!user) {
    return;
  }
  return userUid(user);
}

export function userIsSignedIn(user: User | undefined) {
  if (!user) {
    return;
  }
  return !user.firebaseUserIsAnonymous || user.promotedUid !== undefined;
}

// if firebase is undefined, user will be undefined.
type UserContextValue =
  | { firebase: undefined; user: undefined }
  | {
      firebase: LazyFirebase;
      user: User;
    };
export const UserContext = createContext<UserContextValue>({ firebase: undefined, user: undefined });

export function useUser() {
  return useContext(UserContext);
}
