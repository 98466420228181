import { ReactNode, useEffect, useState } from "react";

import { Specials } from "@sportsball/shared";

import { firebaseConfig } from "../firebaseConfig";
import { SpecialsContext } from "./useSpecials";

export default function SpecialsProvider({ children }: { children: ReactNode }) {
  const [specials, setSpecials] = useState<Specials | undefined>();

  useEffect(() => {
    const url = `${firebaseConfig.databaseURL}/specials.json`;

    (async () => {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept-Encoding": "gzip, deflate", // Request compressed response
          "Content-Type": "application/json", // Ensure JSON response
        },
      });
      // Handle compressed response (if applicable)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = (await response.json()) as unknown;
      const validatedSpecials = Specials.parse(data);
      setSpecials(validatedSpecials);
    })().catch(alert);
  }, []);

  return <SpecialsContext.Provider value={specials}>{children}</SpecialsContext.Provider>;
}
