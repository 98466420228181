import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { SquaresCellData, SquaresGameData } from "@sportsball/shared";

import { SquaresBoard } from "../components/SquaresBoard";
import { LazyFirebase } from "../context/useFirebasePreUser";
import { maybeUserUid, useUser } from "../context/useUser";
import { useEffect, useState } from "react";
import ConfirmDialog from "src/components/ConfirmDialog";

const _100BoardScores = [...Array(10).keys()];

const _goodScores = [0, 7, 3, 4];
const _badScores = [2, 5, 8, 9];
const _middlePair = { multi: [1, 6] };

// Fisher-Yates shuffle
function _shuffleArray<T>(a: T[]) {
  const shuffled = [...a];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

export function generateTargetScores(size: 25 | 100) {
  if (size === 25) {
    // randomly pair up good and bad scores for both home and away
    const awayFourPairs = _shuffleArray(_badScores).map((bad, i) => ({ multi: [_goodScores[i], bad] }));
    const homeFourPairs = _shuffleArray(_badScores).map((bad, i) => ({ multi: [_goodScores[i], bad] }));
    // shuffle in the one _middlePair into away and home scores
    const away = _shuffleArray([...awayFourPairs, _middlePair]);
    const home = _shuffleArray([...homeFourPairs, _middlePair]);
    return { away, home };
  }
  return { away: _shuffleArray(_100BoardScores), home: _shuffleArray(_100BoardScores) };
}

function _lockSquaresGame({
  gameId,
  game,
  firebase,
}: {
  gameId: string;
  game: SquaresGameData;
  firebase: LazyFirebase;
}) {
  (async () => {
    const targetScores = generateTargetScores(game.size);
    const { doc, updateDoc } = firebase.firestorePackage;
    const d = doc(firebase.firestore, `games/${gameId}`);
    await updateDoc(d, { targetScores });
  })().catch(alert);
}

export default function SquaresGame({ gameId, game }: { gameId: string; game: SquaresGameData }) {
  const navigate = useNavigate();
  const { firebase, user } = useUser();
  const [hasEmptySquares, setHasEmptySquares] = useState(false);
  const [showConfirmLockGame, setShowConfirmLockGame] = useState(false);
  const uid = maybeUserUid(user);
  const { cells, size } = game;

  const userEntry = !uid ? undefined : game.entries?.[uid];
  const gameLocked = Boolean(game.targetScores);
  const cellCount = Object.keys(cells || {}).length;

  useEffect(() => {
    setHasEmptySquares(cellCount < size);
  }, [cellCount, size]);

  function handleClaimSquare(cell: string) {
    if (gameLocked || !firebase || !uid) {
      return;
    }
    if (!userEntry) {
      navigate(`/games/${gameId}/join`);
      return;
    }
    (async () => {
      const { deleteField, doc, updateDoc } = firebase.firestorePackage;
      const gameRef = doc(firebase.firestore, `games/${gameId}`);
      if (cells?.[cell]) {
        if (cells[cell].uid === uid) {
          await updateDoc(gameRef, { [`cells.${cell}`]: deleteField() });
        }
      } else {
        const cellData: SquaresCellData = { uid };
        await updateDoc(gameRef, { [`cells.${cell}`]: cellData });
      }
    })().catch(alert);
  }

  return (
    <>
      <Stack direction="column" spacing={2} alignItems="center">
        <SquaresBoard game={game} handleClaimSquare={handleClaimSquare} />
        {userEntry && !gameLocked && (
          <Typography>Tap on empty squares to claim them. Tap again on your squares to unclaim them.</Typography>
        )}
        {!gameLocked && game.uid === uid && (
          <div>
            {firebase && (
              <Button
                variant="contained"
                onClick={() => {
                  if (hasEmptySquares) {
                    setShowConfirmLockGame(true);
                  } else {
                    _lockSquaresGame({ gameId, game, firebase });
                  }
                }}
              >
                LOCK GAME
              </Button>
            )}
          </div>
        )}
      </Stack>
      {firebase && hasEmptySquares && (
        <ConfirmDialog
          prompt="This game still has empty squares. Are you sure you want to lock this game?"
          confirmText="Lock"
          cancelText="Cancel"
          open={showConfirmLockGame}
          confirmFn={() => _lockSquaresGame({ gameId, game, firebase })}
          closeDialog={() => setShowConfirmLockGame(false)}
        />
      )}
    </>
  );
}
