import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { SomeGameData } from "@sportsball/shared";
import { getGameStartTimestamp } from "@sportsball/shared";

import { processError } from "../data/webUtils";
import { useLoggedInFirestoreObject } from "../hooks/loggedInObjectHook";
import { findGame, useUserGames } from "../hooks/userGamesHook";

export interface GameContainerProps {
  game: SomeGameData;
  gameId: string;
  gameOn: boolean;
}

export default function GameContainer({ gameId: gameIdFromArgs }: { gameId?: string }) {
  const { gameId: gameIdFromParams } = useParams();
  const gameId = gameIdFromArgs || gameIdFromParams;
  if (!gameId) {
    throw new Error("Missing gameId");
  }
  const userGames = useUserGames();
  const initialData = findGame(gameId, userGames);
  const { object: game, loading, error } = useLoggedInFirestoreObject(`games/${gameId}`, SomeGameData, initialData);
  const [gameOn, setGameOn] = useState(false);

  const startTimestamp = !game ? undefined : getGameStartTimestamp(game);

  // Every second we check to see if gameOn needs flipping
  useEffect(() => {
    if (startTimestamp === undefined) {
      return;
    }
    setGameOn(Date.now() >= startTimestamp);
    const interval = setInterval(() => {
      setGameOn(Date.now() >= startTimestamp);
    }, 1000);
    return () => clearInterval(interval);
  }, [startTimestamp]);

  if (error || loading || !game) {
    return processError({ loading, error });
  }

  const gameContainerProps: GameContainerProps = { game, gameId, gameOn };
  return <Outlet context={gameContainerProps} />;
}
