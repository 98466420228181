import { ReactNode, useEffect, useState } from "react";

import { firebaseConfig } from "../firebaseConfig";
import { FirebaseContext, LazyFirebase } from "./useFirebasePreUser";

export default function FirebaseProvider({ children }: { children: ReactNode }) {
  const [lazyFirebase, setLazyFirebase] = useState<LazyFirebase | undefined>();

  useEffect(() => {
    (async () => {
      const [appPackage, authPackage, databasePackage, firestorePackage, functionsPackage] = await Promise.all([
        import("@firebase/app"),
        import("@firebase/auth"),
        import("@firebase/database"),
        import("@firebase/firestore"),
        import("@firebase/functions"),
      ]);

      const app = appPackage.initializeApp(firebaseConfig);
      const awaitedFirebase = {
        appPackage,
        authPackage,
        databasePackage,
        firestorePackage,
        functionsPackage,

        app,
        database: databasePackage.getDatabase(app),
        firestore: firestorePackage.getFirestore(app),
        auth: authPackage.initializeAuth(app, {
          persistence: [authPackage.browserLocalPersistence, authPackage.browserSessionPersistence],
          popupRedirectResolver: authPackage.browserPopupRedirectResolver,
        }),
        functions: functionsPackage.getFunctions(app),
      };
      setLazyFirebase(awaitedFirebase);
    })().catch(alert);
  }, []);

  return <FirebaseContext.Provider value={lazyFirebase}>{children}</FirebaseContext.Provider>;
}
