import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { format } from "date-fns";
import deepEqual from "deep-equal";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { FootballLeague, GameType, ScheduledGame, SquaresGameData } from "@sportsball/shared";
import { TeamSlate } from "@sportsball/shared";

import NewGame, { NewSquaresGameData } from "./NewGame";
import { generateTargetScores } from "./SquaresGame";

import { SlateGamesPicker } from "../components/SlateGamesPicker";
import { SquaresBoard } from "../components/SquaresBoard";
import { useSpecials } from "../context/useSpecials";

function _generateSquaresEntry() {
  // random name initials created from all upper case characters except Q, X, Y, Z. Two or three
  // characters long. generated straight from github copilot
  // two 2/3 of the time and three 1/3 of the time
  const initialsLength = Math.random() < 0.33 ? 3 : 2;
  const initials = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    .replace(/[QXYZ]/g, "")
    .split("")
    .sort(() => Math.random() - 0.5)
    .slice(0, initialsLength)
    .join("");
  return { initials };
}

function _makeGameData(footballGame: ScheduledGame, size: 25 | 100): NewSquaresGameData {
  return {
    type: GameType.enum.Squares,
    size,
    startTimestamp: footballGame.startTimestamp,
    footballGames: { [footballGame.id]: { ...footballGame, status: "NS" } },
    squaresPerPlayer: 1,
  };
}

export default function NewSquaresGame() {
  const {
    specialId: specialIdParam,
    teamSlate: teamSlateParam,
    leagueId: leagueIdParam,
  } = useParams() as { specialId?: string; teamSlate?: string; leagueId?: string };

  function initialTeamSlate(): TeamSlate {
    if (teamSlateParam) {
      if (!Object.values(TeamSlate).includes(teamSlateParam as TeamSlate)) {
        throw new Error(`Invalid teamSlate: ${teamSlateParam}`);
      }
      return teamSlateParam as TeamSlate;
    }
    if (leagueIdParam) {
      if (!Object.values(FootballLeague.enum).includes(leagueIdParam as FootballLeague)) {
        throw new Error(`Invalid leagueId: ${leagueIdParam}`);
      }
      if (leagueIdParam !== FootballLeague.enum.NFL) {
        return TeamSlate.ApTop25;
      }
    }
    return TeamSlate.NFL;
  }

  const [teamSlate, setTeamSlate] = useState(initialTeamSlate());
  const [week, setWeek] = useState<string | undefined>();
  const [slateWeekGames, setSlateWeekGames] = useState<ScheduledGame[]>([]);

  const [selectedGame, setSelectedGame] = useState<ScheduledGame | undefined>();
  const [size, setSize] = useState<25 | 100>(25);

  const [exampleData, setExampleData] = useState<SquaresGameData | undefined>();
  const [newGameData, setNewGameData] = useState<NewSquaresGameData | undefined>();

  // specials are loaded async so we smuggle any specialId through pendingJumpToSpecial
  // for a one time specials lookup once they are loaded.
  const [pendingJumpToSpecial, setPendingJumpToSpecial] = useState(specialIdParam);

  const specials = useSpecials();

  // Handle setting up the special game if we have a specialId
  useEffect(() => {
    if (!pendingJumpToSpecial) {
      return;
    }
    if (selectedGame) {
      setPendingJumpToSpecial(undefined);
      return;
    }
    if (!specials) {
      return;
    }
    const special = specials[pendingJumpToSpecial];
    const now = Date.now();
    const specialGame = special.find((game) => game.startTimestamp > now);
    if (specialGame) {
      setSelectedGame(specialGame);
      setWeek(specialGame.week);
      setSlateWeekGames([specialGame]);
    }
    setPendingJumpToSpecial(undefined);
  }, [specials, pendingJumpToSpecial, selectedGame]);

  // change of pickedTeamSlate can invalidate selectedGame
  useEffect(() => {
    // see if we need to reset the selectedGame
    if (!slateWeekGames) {
      if (selectedGame) {
        setSelectedGame(undefined);
      }
      return;
    }

    let updatedGame = !selectedGame ? undefined : slateWeekGames.find((game) => game.id === selectedGame.id);
    if (!updatedGame) {
      updatedGame = slateWeekGames[0];
    }

    if (!deepEqual(updatedGame, selectedGame)) {
      setSelectedGame(updatedGame);
    }
  }, [slateWeekGames, selectedGame]);

  // rebuild example game data from dependent state
  useEffect(() => {
    if (!selectedGame) {
      return;
    }
    const newGame = _makeGameData(selectedGame, size);
    const exampleGame: SquaresGameData = {
      ...newGame,
      targetScores: generateTargetScores(size),
      name: "",
      uid: "",
      code: "",
      description: "",
      entries: {},
      cells: {},
    };
    for (let row = 0; row < Math.sqrt(exampleGame.size); row++) {
      for (let col = 0; col < Math.sqrt(exampleGame.size); col++) {
        const cell = `row-${row}-col-${col}`;
        exampleGame.entries![cell] = _generateSquaresEntry();
        exampleGame.cells![cell] = { uid: cell };
      }
    }
    exampleGame.footballGames = {
      [selectedGame.id]: {
        ...selectedGame,
        status: "Q4",
        scores: {
          q1: { away: 0, home: 3 },
          q2: { away: 3, home: 0 },
          q3: { away: 10, home: 0 },
          q4: { away: 22, home: 16 },
          overtime: { away: 0, home: 0 },
          total: { away: 0, home: 0 },
        },
      },
    };
    setExampleData(exampleGame);
  }, [selectedGame, size]);

  // any changes to selectedGame or size should trigger a rebuild of newGameData
  useEffect(() => {
    const thisNewGameData = selectedGame ? _makeGameData(selectedGame, size) : undefined;
    if (!deepEqual(newGameData, thisNewGameData)) {
      setNewGameData(thisNewGameData);
    }
  }, [selectedGame, newGameData, setNewGameData, size]);

  function setFootballGameId(id: string) {
    const newGame = slateWeekGames?.find((game) => game.id === id);
    if (newGame) {
      setSelectedGame(newGame);
    }
  }

  const singleGameFocused = Boolean(specialIdParam || leagueIdParam || teamSlateParam);
  return (
    <NewGame type={GameType.enum.Squares} newGameData={newGameData} singleGameFocused={singleGameFocused}>
      <Stack spacing={2} sx={{ borderRadius: 4, bgcolor: "white", padding: 2 }}>
        <SlateGamesPicker
          show={!singleGameFocused}
          teamSlate={teamSlate}
          title="Choose any NFL or NCAA game"
          description="Sportsball can run a squares game for any football game! Choose a game below."
          setTeamSlate={setTeamSlate}
          week={week}
          setWeek={setWeek}
          slateWeekGames={slateWeekGames}
          setSlateWeekGames={setSlateWeekGames}
        />
        {selectedGame && slateWeekGames && (
          <TextField
            select
            id="game"
            label={`${teamSlate} Game`}
            value={selectedGame?.id ?? ""}
            SelectProps={{
              MenuProps: {
                style: {
                  maxHeight: 400,
                },
              },
            }}
            onChange={(e) => setFootballGameId(e.target.value)}
          >
            {slateWeekGames.map(({ startTimestamp, teams, id }) => {
              const date = format(startTimestamp, "EEE p");
              const { away, home } = teams;
              return (
                <MenuItem key={id} value={id}>
                  {away.name}
                  {away.rank && `#${away.rank} `} @ {home.name}
                  {home.rank && `#${home.rank}`} - {date}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <h2>Choose the squares board size</h2>
        <p>
          For a simpler game with more balanced odds, choose a 5x5 board. 5x5 boards assign two scores to each row and
          column. 10x10 boards are available for the classic squares experience.
        </p>
        <Box display="flex" justifyContent="center">
          <ButtonGroup variant="contained" aria-label="Game Type">
            <Button variant={size === 25 ? "contained" : "outlined"} onClick={() => setSize(25)}>
              5x5
            </Button>
            <Button variant={size === 100 ? "contained" : "outlined"} onClick={() => setSize(100)}>
              10x10
            </Button>
          </ButtonGroup>
        </Box>
        <h2>Game Preview</h2>
        {selectedGame && exampleData && <SquaresBoard game={exampleData} />}
      </Stack>
    </NewGame>
  );
}
