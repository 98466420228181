import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import Person from "@mui/icons-material/Person";

import AvatarIcon from "../avatar/AvatarIcon";
import { userIsSignedIn, useUser } from "../context/useUser";
import SignInDialog from "./SignInDialog";
import UserPrefsDialog from "./UserPrefsDialog";
import AppDrawer from "./AppDrawer";

export default function Titlebar({ serviceVersion }: { serviceVersion: string | undefined }) {
  const navigate = useNavigate();
  const { firebase, user } = useUser();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showUserPrefs, setShowUserPrefs] = useState(false);

  function onSignOut() {
    setDrawerOpen(false);

    if (!user || !firebase) {
      return;
    }
    if (user.promotedUid) {
      const { collection, doc, deleteDoc } = firebase.firestorePackage;
      deleteDoc(doc(collection(firebase.firestore, "anon-promotions"), user.firebaseUid)).catch(alert);
    } else {
      firebase.authPackage.signInAnonymously(firebase.auth).catch(alert);
    }
  }

  const isSignedIn = user !== undefined && userIsSignedIn(user);
  return (
    <>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            fontFamily="Nico Moji"
            variant="h6"
            component="div"
            flexGrow={1}
            onClick={() => navigate("/")}
            sx={{ cursor: "pointer" }}
          >
            SPORTSBALL
          </Typography>
          {user && (
            <IconButton onClick={() => (isSignedIn ? setShowUserPrefs(true) : setShowSignIn(true))}>
              {!user.prefs?.avatar ? (
                <Person fontSize="large" />
              ) : (
                <AvatarIcon avatarData={user.prefs.avatar} size="medium" />
              )}
            </IconButton>
          )}
        </Toolbar>
        {user && firebase && <SignInDialog open={showSignIn} closeDialog={() => setShowSignIn(false)} />}
        {user && firebase && <UserPrefsDialog open={showUserPrefs} closeDialog={() => setShowUserPrefs(false)} />}
      </AppBar>
      <nav>
        <AppDrawer
          open={drawerOpen}
          closeDrawer={() => setDrawerOpen(false)}
          onSignIn={
            !isSignedIn
              ? () => {
                  setShowSignIn(true);
                }
              : undefined
          }
          onSignOut={isSignedIn ? onSignOut : undefined}
          serviceVersion={serviceVersion}
        />
      </nav>
    </>
  );
}
