import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import GridOnIcon from "@mui/icons-material/GridOn";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import Person from "@mui/icons-material/Person";
import PersonOff from "@mui/icons-material/PersonOff";
import SearchIcon from "@mui/icons-material/Search";

import { GameAndId, searchByCode } from "../MyGames";
import AboutBox from "./AboutBox";
import { useUser } from "../context/useUser";

export default function AppDrawer({
  open,
  closeDrawer,
  serviceVersion,
  onSignIn,
  onSignOut,
}: {
  open: boolean;
  closeDrawer: () => void;
  serviceVersion: string | undefined;
  onSignIn?: () => void;
  onSignOut?: () => void;
}) {
  const navigate = useNavigate();
  const { firebase } = useUser();
  const [showSearchByCode, setShowSearchByCode] = useState(false);
  const [searchGameCode, setSearchGameCode] = useState("");
  const [searchGame, setSearchGame] = useState<GameAndId | undefined>();
  const [showAboutBox, setShowAboutBox] = useState(false);

  useEffect(() => {
    if (searchGame) {
      closeDrawer();
      navigate(`/games/${searchGame.id}`);
      return;
    }
    if (!showSearchByCode || !searchGameCode) {
      return;
    }
    return searchByCode(searchGameCode, setSearchGame, firebase);
  }, [closeDrawer, navigate, searchGame, showSearchByCode, searchGameCode, firebase]);

  function closeDrawerAnd(fn: () => void) {
    closeDrawer();
    fn();
  }

  function searchByCodeDialog() {
    return (
      <Dialog
        open={showSearchByCode}
        onClose={() => setShowSearchByCode(false)}
        aria-labelledby="search-by-code-dialog-title"
        aria-describedby="search-by-code-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Search by code</DialogTitle>
        <DialogContent>
          <DialogContentText id="search-by-code-dialog-description">
            Enter the code of the game you want to join
          </DialogContentText>
          <TextField
            margin="dense"
            id="code"
            name="code"
            label="Game code"
            fullWidth
            variant="standard"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
            onChange={(event) => setSearchGameCode(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDrawerAnd(() => setShowSearchByCode(false))}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onClose={closeDrawer}>
      <Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => closeDrawerAnd(() => navigate("/"))}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => setShowSearchByCode(true)}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Find game by code" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => closeDrawerAnd(() => navigate("/football-pools/squares/online/new"))}>
              <ListItemIcon>
                <GridOnIcon />
              </ListItemIcon>
              <ListItemText primary="New squares game" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => closeDrawerAnd(() => navigate("/football-pools/pickem/online/new"))}>
              <ListItemIcon>
                <LibraryAddCheckIcon />
              </ListItemIcon>
              <ListItemText primary="New pickem pool" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => setShowAboutBox(true)}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
        </List>
        {(onSignIn || onSignOut) && <Divider />}
        {onSignIn && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => closeDrawerAnd(onSignIn)}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Sign in" />
            </ListItemButton>
          </ListItem>
        )}
        {onSignOut && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => closeDrawerAnd(onSignOut)}>
              <ListItemIcon>
                <PersonOff />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItemButton>
          </ListItem>
        )}
      </Box>
      <AboutBox open={showAboutBox} serviceVersion={serviceVersion} closeDialog={() => setShowAboutBox(false)} />
      {searchByCodeDialog()}
    </Drawer>
  );
}
