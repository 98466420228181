import { entryScore, PickemEntryData, PickemGameData } from "@sportsball/shared";

export type ScoringTier = { score: number; entries: { [entryUid: string]: PickemEntryData } };

export function getScoringTiers(game: PickemGameData): ScoringTier[] {
  const leaderboard = Object.entries(game.entries ?? {})
    .map(([entryUid, entry]) => ({
      entryUid,
      entry,
      score: entryScore(entry, game.questions, game.actualAnswers ?? {}),
    }))
    .sort((a, b) => b.score - a.score);

  const scoringTiers: ScoringTier[] = [];
  for (const { entryUid, entry, score } of leaderboard) {
    const lastScoringTier = scoringTiers[scoringTiers.length - 1];
    if (scoringTiers.length === 0 || lastScoringTier.score !== score) {
      scoringTiers.push({ score, entries: { [entryUid]: entry } });
    } else {
      lastScoringTier.entries[entryUid] = entry;
    }
  }

  return scoringTiers;
}

export function sortEntries(entries: { [entryUid: number]: PickemEntryData }) {
  // eslint-disable-next-line no-unused-vars
  return Object.entries(entries).sort(([_, aEntry], [__, bEntry]) => {
    const scoreDiff = (bEntry.questionWinProbability ?? 0) - (aEntry.questionWinProbability ?? 0);
    return scoreDiff !== 0 ? scoreDiff : aEntry.screenName.localeCompare(bEntry.screenName);
  });
}
