import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

import CloudIcon from "@mui/icons-material/Cloud";
import PersonIcon from "@mui/icons-material/Person";

//import packageJson from "../../package.json";
import { useUser } from "../context/useUser";
import { origin } from "../main";

/*
interface _ImportMeta {
  readonly env:
    | {
        readonly VITE_APP_GIT_SHA: string | undefined;
      }
    | undefined;
}

const _importMeta = typeof import.meta !== "undefined" ? (import.meta as unknown as _ImportMeta) : undefined;
*/

export default function AboutBox({
  open,
  serviceVersion,
  closeDialog,
}: {
  open: boolean;
  serviceVersion: string | undefined;
  closeDialog: () => void;
}) {
  const { user } = useUser();
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>About Sportsball</DialogTitle>
      <DialogContent>
        {/*
        <DialogContentText>
          v{packageJson.version} #{_importMeta.env?.VITE_APP_GIT_SHA ?? "-"}
        </DialogContentText>
        */}
        <DialogContentText>{origin()}</DialogContentText>
        <Stack alignItems="center" direction="row" gap={0.5}>
          <CloudIcon />
          <DialogContentText>{serviceVersion ?? "-"}</DialogContentText>
        </Stack>
        <Stack alignItems="center" direction="row" gap={0.5}>
          <PersonIcon />
          <DialogContentText>{user?.firebaseUid ?? "-"}</DialogContentText>
        </Stack>
        {user?.promotedUid && <DialogContentText>DBA: {user.promotedUid}</DialogContentText>}
        <DialogContentText>© Sportsball 2024</DialogContentText>
        <br />
        <DialogContentText>
          Vectors and icons by{" "}
          <Link href="http://laurareen.com/?ref=svgrepo.com" target="_blank">
            Laura Reen
          </Link>{" "}
          in CC Attribution License via{" "}
          <Link href="https://www.svgrepo.com/" target="_blank">
            SVG Repo
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
