import { useCallback, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import type { DocumentData, QueryConstraint } from "firebase/firestore";

import { ZodSchema } from "zod";

import { maybeUserUid, useUser } from "../context/useUser";
import { LazyFirebase } from "../context/useFirebasePreUser";

export interface FirestoreQueryData<T> {
  id: string;
  data: T;
}

export function useFirestoreQuery<T>({
  queryKey,
  collectionName,
  whereClause,
  schema,
}: {
  queryKey: string;
  collectionName: string;
  // eslint-disable-next-line no-unused-vars
  whereClause: ({ firebase, uid }: { firebase: LazyFirebase; uid: string }) => QueryConstraint[];
  schema: ZodSchema<T>;
}) {
  const { firebase, user } = useUser();
  const queryClient = useQueryClient();
  const uid = maybeUserUid(user);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to render with change to schema object.
  const safeParse = useCallback((data: DocumentData | undefined) => schema.safeParse(data), []);

  // Initialize React Query with an empty array as initial data
  const query = useQuery<FirestoreQueryData<T>[]>([queryKey, uid], {
    queryFn: () => [],
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: Boolean(queryKey.length > 0 && uid),
  });

  useEffect(() => {
    if (!firebase || !uid) {
      return;
    }

    // Construct Firestore query with where clauses
    const { collection, onSnapshot, query } = firebase.firestorePackage;
    const ref = query(collection(firebase.firestore, collectionName), ...whereClause({ firebase, uid }));
    return onSnapshot(
      ref,
      (snapshot) => {
        const data: FirestoreQueryData<T>[] = [];
        for (const doc of snapshot.docs) {
          const parsedData = safeParse(doc.data());
          if (!parsedData.success) {
            console.error("Data validation failed:", parsedData.error.errors);
            continue;
          }
          // VERY strange case here where our entry tiebreaker data comes out undefined when it comes
          // out of zod parsing. Go back to doc.data() as T for now :/
          data.push({ id: doc.id, data: doc.data() as T });
        }
        queryClient.setQueryData([queryKey, uid], data);
      },
      (error) => {
        console.error("Error subscribing to Firestore data:", error);
      }
    );
  }, [uid, firebase, queryKey, collectionName, safeParse, whereClause, queryClient]);

  return query;
}
