import { Box, Typography, Paper, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";

export default function HowToPlayPickem() {
  return (
    <Container maxWidth="md">
      <Helmet>
        <title>How to Play Football Pick&apos;em Pools - Sportsball</title>
        <meta
          name="description"
          content="Learn how to play football pick'em pools online. Easy guide to joining pools, making picks, and winning strategies."
        />
      </Helmet>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h1" gutterBottom sx={{ fontSize: "2.5rem" }}>
          How to Play Football Pick&apos;em Pools
        </Typography>

        <Typography variant="h2" gutterBottom sx={{ fontSize: "1.8rem", mt: 4 }}>
          Quick Start Guide
        </Typography>
        <Box component="section" sx={{ mb: 4 }}>
          <Typography paragraph>
            Football pick&apos;em pools are simple: pick the winners of each game, score points for correct picks, and
            compete against friends and colleagues.
          </Typography>
          {/* Add more content */}
        </Box>

        <Typography variant="h2" gutterBottom sx={{ fontSize: "1.8rem" }}>
          How to Join a Pool
        </Typography>
        <Box component="section" sx={{ mb: 4 }}>
          <Typography paragraph>1. Get an invite link from the pool commissioner</Typography>
          <Typography paragraph>2. Click the link to join the pool</Typography>
          <Typography paragraph>3. Make your picks before the games start</Typography>
          {/* Add more steps */}
        </Box>

        {/* Add more sections */}
      </Paper>
    </Container>
  );
}
