import React, { useEffect, useState } from "react";

import AvatarGroup from "@mui/material/AvatarGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { isGameOff, PickemEntryData, PickemGameData, QuestionType, Side } from "@sportsball/shared";

import PickemSheet from "../components/PickemSheet";
import AvatarIcon from "../avatar/AvatarIcon";
import { userIsSignedIn, useUser } from "../context/useUser";
import SignInDialog from "../components/SignInDialog";

export function JoinPickemGame({
  gameAndId,
  setEntry,
}: {
  gameAndId: { gameId: string; game: PickemGameData };
  // eslint-disable-next-line no-unused-vars
  setEntry: (entry: PickemEntryData | undefined) => void;
}) {
  const { user } = useUser();
  const { game } = gameAndId;
  const slateSize = game.questions.filter((question) => !isGameOff(question)).length;
  const [screenName, setScreenName] = useState(user?.prefs?.preferredScreenName ?? "");
  const [selectedAnswers, setSelectedAnswers] = useState<Record<string, Side>>({});
  const [tiebreakerPoints, setTiebreakerPoints] = useState<number | undefined>();
  const [showSignIn, setShowSignIn] = useState(false);

  const { set: avatarSet, id: avatarId } = user?.prefs?.avatar ?? {};

  const isReady =
    screenName.length > 0 &&
    Object.keys(selectedAnswers).length === slateSize &&
    (tiebreakerPoints !== undefined || !game.tiebreakerId);
  useEffect(() => {
    if (isReady) {
      setEntry({
        screenName,
        selectedAnswers,
        ...(tiebreakerPoints !== undefined && { tiebreakerPoints }),
        ...(avatarId !== undefined && avatarSet !== undefined && { avatar: { set: avatarSet, id: avatarId } }),
      });
    } else {
      setEntry(undefined);
    }
  }, [isReady, slateSize, selectedAnswers, screenName, avatarSet, avatarId, tiebreakerPoints, setEntry]);

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setScreenName(event.target.value.trim());
  }

  function makePick({ questionNumber, pick }: { questionNumber: number; pick: Side }) {
    const newAnswers = { ...selectedAnswers };
    newAnswers[questionNumber.toString()] = pick;
    setSelectedAnswers(newAnswers);
  }

  function randomizeAnswers() {
    const randomAnswers: Record<string, Side> = {};
    for (let questionNumber = 0; questionNumber < game.questions.length; questionNumber++) {
      const question = game.questions[questionNumber];
      if (isGameOff(question)) {
        continue;
      }

      randomAnswers[questionNumber.toString()] = Math.random() < 0.5 ? Side.enum.Away : Side.enum.Home;
    }
    setSelectedAnswers(randomAnswers);
  }

  const isSignedIn = user && userIsSignedIn(user);
  const entries = Object.entries(game.entries ?? {});
  const pickemGameType = game.questions.length > 0 ? game.questions[0].type : undefined;

  return (
    <>
      <form>
        {entries.length > 0 && (
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <Typography>Other players:</Typography>
            <AvatarGroup max={10}>
              {entries.map(([uid, entry]) => (
                <AvatarIcon key={uid} avatarData={entry.avatar} size="medium" />
              ))}
            </AvatarGroup>
          </Stack>
        )}
        <p>{game.description}</p>
        {pickemGameType === QuestionType.enum.MoneylinePick && (
          <p>
            This pool uses Money Line scoring. Choose the teams that you think will win. Every winning pick with earn
            you the points assigned to that team. Winning with a <i>favorite</i> will earn you{" "}
            <span style={{ fontSize: "1.2em" }}>①</span> point. Winning with an <i>underdog</i> will earn you up to{" "}
            <span style={{ fontSize: "1.2em" }}>⑩</span> points depending on the odds. Choose your picks wisely!
          </p>
        )}
        {pickemGameType === QuestionType.enum.VsSpreadPick && (
          <p>
            This pool uses <i>Against the Spread</i> scoring. Underdogs have a + number next to their name and that
            number is the <i>spread</i>. If you pick an underdog you can still win even if that team loses as long as
            they lose by less than the spread. If you pick the favorite, they will have to win by <i>more than</i> the
            spread for you to be victorious! Every win is worth 1 point.
          </p>
        )}
        {!isSignedIn && (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Button fullWidth={false} onClick={() => setShowSignIn(true)}>
              Sign In
            </Button>
            <Typography>For the best experience!</Typography>
          </Stack>
        )}
        <h2>Enter Your Screen Name</h2>
        <TextField
          id="screenName"
          defaultValue={screenName}
          label="Screen Name"
          variant="outlined"
          onChange={handleNameChange}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <h2>Make Your Picks</h2>
          <IconButton onClick={randomizeAnswers}>🎲</IconButton>
        </Stack>
        <PickemSheet
          game={game}
          picks={selectedAnswers}
          tiebreakerPoints={tiebreakerPoints}
          makePick={makePick}
          setTiebreakerPoints={setTiebreakerPoints}
        />
      </form>
      <SignInDialog open={showSignIn} closeDialog={() => setShowSignIn(false)} />
    </>
  );
}
