import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { singleFootballGame, teamLabel } from "@sportsball/shared";
import { MultiTargetScore, FootballGame, SquaresPeriod } from "@sportsball/shared";
import { NewSquaresGameData } from "../pages/NewGame";
import { InitialsProps, ScoreProps, TitleProps } from "../style";
import { getPeriodScores } from "./PeriodScore";
import { SquaresScoreboard } from "./SquaresScoreboard";

function _sizeDimension(size: 25 | 100) {
  return size === 25 ? 5 : 10;
}

const _lightGridGuideColor = "#acffac";
const _darkGridGuideColor = "#33f033";

const _periodMarkers = {
  [SquaresPeriod.enum.Q1]: "Q1",
  [SquaresPeriod.enum.Half]: "H",
  [SquaresPeriod.enum.Q3]: "Q3",
  [SquaresPeriod.enum.Final]: "F",
};

export function getActivePeriod(footballGame: FootballGame): SquaresPeriod | undefined {
  const { status } = footballGame;
  if (status === "Q1") {
    return SquaresPeriod.enum.Q1;
  }
  if (status === "Q2") {
    return SquaresPeriod.enum.Half;
  }
  if (status === "Q3") {
    return SquaresPeriod.enum.Q3;
  }
  if (status === "Q4" || status === "OT") {
    return SquaresPeriod.enum.Final;
  }
}

export interface GameScore {
  away: number;
  home: number;
}

export function SquaresBoard({
  game,
  handleClaimSquare,
}: {
  game: NewSquaresGameData;
  // eslint-disable-next-line no-unused-vars
  handleClaimSquare?: (cell: string) => void;
}) {
  const { cells, payouts, size, targetScores } = game;
  const footballGame = singleFootballGame(game);
  if (!footballGame) {
    throw new Error("Squares game without football game");
  }
  const periodScores = getPeriodScores(footballGame);
  const activePeriod = getActivePeriod(footballGame);
  const activePeriodScore = activePeriod ? periodScores[activePeriod] : undefined;

  function TargetScore({
    score,
    side,
    visibility,
  }: {
    score: number | MultiTargetScore | undefined;
    side: "Home" | "Away";
    visibility?: "visible" | "hidden";
  }) {
    let textcolor: string | undefined;
    let bgcolor: string | undefined;
    if (score && activePeriodScore) {
      if (getScoreMatches(side === "Away" ? activePeriodScore.away : activePeriodScore.home, score)) {
        bgcolor = _lightGridGuideColor;
      }
    }
    if (size === 100) {
      return (
        <Grid size={1} borderBottom={2} borderRight={2} bgcolor={bgcolor}>
          <Typography
            {...ScoreProps}
            fontWeight="bold"
            textAlign="center"
            color={textcolor}
            marginY={1.07}
            visibility={visibility}
          >
            {score === undefined || typeof score !== "number" ? "?" : score}
          </Typography>
        </Grid>
      );
    }
    let one = "?";
    let two = "?";
    if (score) {
      if (typeof score !== "object") {
        throw new Error("Not implemented");
      }
      one = score.multi[0].toString();
      two = score.multi[1].toString();
    }
    return (
      <Grid size={1} borderBottom={2} borderRight={2} bgcolor={bgcolor}>
        <Stack direction="row" justifyContent="center" visibility={visibility}>
          <Box marginBottom={2}>
            <Typography {...ScoreProps} fontWeight="bold" color={textcolor}>
              {one}
            </Typography>
          </Box>
          <Box marginY={1.07}>
            <Typography {...ScoreProps} color={textcolor} sx={{ transform: "rotate(45deg)" }}>
              |
            </Typography>
          </Box>
          <Box marginTop={2}>
            <Typography {...ScoreProps} color={textcolor} fontWeight="bold">
              {two}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    );
  }

  const _dimension = [...Array(_sizeDimension(size)).keys()];

  function awayScoreCol() {
    return (
      <Grid container columns={1} width="50px">
        <TargetScore score={undefined} side="Away" visibility={"hidden"} />
        {_dimension.map((i) => (
          <TargetScore key={i} side="Away" score={targetScores?.away?.[i]} />
        ))}
      </Grid>
    );
  }

  function homeScoreRow() {
    return _dimension.map((i) => <TargetScore key={i} side="Home" score={targetScores?.home?.[i]} />);
  }

  function getInitials(initials: string | undefined) {
    if (!initials) {
      return "-";
    }
    const segmenter = new Intl.Segmenter("en", { granularity: "grapheme" });
    const segments = Array.from(segmenter.segment(initials)).map((s) => s.segment);
    return segments.slice(0, 3).join("");
  }

  function boardRow(row: number) {
    return _dimension.map((col) => {
      const cell = `row-${row}-col-${col}`;
      const squareUid = cells?.[cell]?.uid;
      const squareOwner = squareUid ? game.entries?.[squareUid] : undefined;
      const initials = getInitials(squareOwner?.initials);
      const wins = getWins({ row, col });

      const matchesAway =
        targetScores && activePeriodScore && getScoreMatches(activePeriodScore.away, targetScores.away[row]);
      const matchesHome =
        targetScores && activePeriodScore && getScoreMatches(activePeriodScore.home, targetScores.home[col]);
      const activeWinner = matchesAway && matchesHome;
      const bgcolor = activeWinner
        ? _darkGridGuideColor
        : matchesAway || matchesHome
        ? _lightGridGuideColor
        : undefined;

      return (
        <Grid
          key={col}
          position="relative"
          size={1}
          paddingY={1}
          textAlign="center"
          onClick={() => handleClaimSquare?.(cell)}
          borderBottom={2}
          borderRight={2}
          bgcolor={bgcolor}
        >
          <Typography
            {...InitialsProps}
            fontWeight={activeWinner ? "900" : "400"}
            visibility={squareOwner ? "visible" : "hidden"}
          >
            {initials}
          </Typography>
          {wins.length > 0 && (
            <Typography position="absolute" fontSize={8} bottom={0} left={2} color="red" fontWeight="bold">
              {wins.join("+")}
            </Typography>
          )}
        </Grid>
      );
    });
  }

  function squaresGrid() {
    function theGrid(columns: number) {
      return (
        <Grid container columns={columns} width="100%">
          {homeScoreRow()}
          {_dimension.map((row) => boardRow(row))}
        </Grid>
      );
    }
    if (size === 100) {
      return (
        <Box overflow="auto" sx={{ touchAction: "auto" }}>
          <Box width={500}>{theGrid(10)}</Box>
        </Box>
      );
    }
    return theGrid(5);
  }

  function getScoreMatches(periodScore: number, targetScore: number | MultiTargetScore) {
    const periodDigit = periodScore % 10;
    if (typeof targetScore === "number") {
      return periodDigit === targetScore;
    }
    return targetScore.multi.includes(periodDigit);
  }

  function getWins({ row, col }: { row: number; col: number }) {
    const wins: string[] = [];
    if (targetScores) {
      for (const period of [
        SquaresPeriod.enum.Q1,
        SquaresPeriod.enum.Half,
        SquaresPeriod.enum.Q3,
        SquaresPeriod.enum.Final,
      ]) {
        const scores = periodScores[period];
        if (!scores) {
          break;
        }
        if (
          getScoreMatches(scores.away, targetScores.away[row]) &&
          getScoreMatches(scores.home, targetScores.home[col])
        ) {
          if (payouts?.[period]) {
            wins.push(`$${payouts?.[period]}`);
          } else {
            wins.push(_periodMarkers[period]);
          }
        }
      }
    }
    return wins;
  }

  return (
    <>
      <Box width="100%">
        <Stack>
          <Typography variant="h5" align="center" padding={1} {...TitleProps}>
            {teamLabel(footballGame.teams, "home")}
          </Typography>
          <Stack direction="row" justifyContent="center">
            <Typography
              variant="h5"
              align="center"
              padding={1}
              {...TitleProps}
              sx={{ writingMode: "vertical-lr", transform: "rotate(180deg)", marginLeft: -3 }}
            >
              {teamLabel(footballGame.teams, "away")}
            </Typography>
            {awayScoreCol()}
            {squaresGrid()}
          </Stack>
        </Stack>
      </Box>
      <SquaresScoreboard footballGame={footballGame} />
    </>
  );
}
