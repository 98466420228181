import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { PickemGameData, PickemEntryData } from "@sportsball/shared";
import EntryLine from "../components/EntryLine";
import { getScoringTiers, sortEntries } from "../data/scoringTiers";

export default function QuestionsGame({
  gameId,
  game,
  gameOn,
}: {
  gameId: string;
  game: PickemGameData;
  gameOn: boolean;
}) {
  const scoringStarted = "actualAnswers" in game;

  function TierEntries({
    score,
    entries,
  }: {
    score: number | undefined;
    entries: { [entryUid: number]: PickemEntryData };
  }) {
    const sortedEntries = sortEntries(entries);
    return sortedEntries.map(([entryUid, entry]) => (
      <EntryLine key={entryUid} score={score} entry={entry} entryUid={entryUid} gameId={gameId} gameOn={gameOn} />
    ));
  }

  function PregameLeaderboard() {
    if (!game.entries) {
      return (
        <Typography variant="h5" align="center">
          No entries
        </Typography>
      );
    }
    const sortedEntries = sortEntries(game.entries);
    return (
      <Stack>
        {sortedEntries.map(([entryUid, entry]) => (
          <EntryLine key={entryUid} entry={entry} entryUid={entryUid} score={0} gameId={gameId} gameOn={gameOn} />
        ))}
      </Stack>
    );
  }

  const scoringTiers = scoringStarted ? getScoringTiers(game) : undefined;

  function ScoringLeaderboard() {
    if (!game.entries || !scoringTiers) {
      return <Typography variant="h5">No entries</Typography>;
    }
    return (
      <>
        {scoringTiers.map(({ score, entries }, i) => (
          <TierEntries key={i} score={score} entries={entries} />
        ))}
      </>
    );
  }

  return (
    <Stack spacing={2} p={2} width="100%">
      {!scoringTiers && <PregameLeaderboard />}
      {scoringTiers && <ScoringLeaderboard />}
    </Stack>
  );
}
