import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { SomeGameData } from "@sportsball/shared";
import { getGameStartTimestamp, isSquaresGame, singleFootballGame } from "@sportsball/shared";

import Countdown from "./Countdown";
import { GameAndId } from "../MyGames";
import { SquaresScoreboard } from "./SquaresScoreboard";
import GameTitle from "./GameTitle";

export function GameSummary({ game }: { game: SomeGameData }) {
  if (isSquaresGame(game)) {
    const footballGame = singleFootballGame(game);
    if (!footballGame) {
      throw new Error("Squares game without football game");
    }
    return <SquaresScoreboard footballGame={footballGame} />;
  }
  return <div />;
}

function GameCard({ gameAndId }: { gameAndId: GameAndId }) {
  const navigate = useNavigate();
  const { id: gameId, data: game } = gameAndId;
  const footballGame = singleFootballGame(game);
  const startTimestamp = getGameStartTimestamp(game);
  return (
    <Card sx={{ borderRadius: 4 }}>
      <Stack
        spacing={2}
        p={2}
        bgcolor="lightblue"
        onClick={() => navigate(`/games/${gameId}`)}
        sx={{ cursor: "pointer" }}
      >
        <GameTitle gameAndId={gameAndId} />
        {footballGame && (
          <Stack spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Stack direction="column" alignItems="center">
              <Typography>{footballGame.teams.away.city}</Typography>
              <Typography variant="h5">{footballGame.teams.away.name}</Typography>
            </Stack>
            <Typography variant="h6" fontFamily="Timmana">
              VS
            </Typography>
            <Stack direction="column" alignItems="center">
              <Typography>{footballGame.teams.home.city}</Typography>
              <Typography variant="h5">{footballGame.teams.home.name}</Typography>
            </Stack>
          </Stack>
        )}
        {startTimestamp && <Countdown startTimestamp={startTimestamp} startedElement={<GameSummary game={game} />} />}
      </Stack>
    </Card>
  );
}

export default GameCard;
