import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SportsFootballIcon from "@mui/icons-material/SportsFootball";

import { FootballGame, SquaresPeriod } from "@sportsball/shared";

import { GameScore } from "./SquaresBoard";
import { ScoreProps } from "../style";

export function getPeriodScores(footballGame: FootballGame) {
  const scores: Partial<Record<SquaresPeriod, GameScore | undefined>> = {};

  function addScore(a: GameScore | undefined, b: GameScore | undefined) {
    if (!a || !b) {
      return;
    }
    return { away: a.away + b.away, home: a.home + b.home };
  }

  scores[SquaresPeriod.enum.Q1] = footballGame.scores?.q1;
  scores[SquaresPeriod.enum.Half] = addScore(scores[SquaresPeriod.enum.Q1], footballGame.scores?.q2);
  scores[SquaresPeriod.enum.Q3] = addScore(scores[SquaresPeriod.enum.Half], footballGame.scores?.q3);
  const q4Score = addScore(scores[SquaresPeriod.enum.Q3], footballGame.scores?.q4);
  scores[SquaresPeriod.enum.Final] = !footballGame.scores?.overtime
    ? q4Score
    : addScore(q4Score, footballGame.scores?.overtime);

  return scores;
}

export type PeriodScores = ReturnType<typeof getPeriodScores>;

export function PeriodScore({
  period,
  score,
  isActive,
}: {
  period: SquaresPeriod;
  score: GameScore | undefined;
  isActive: boolean;
}) {
  return (
    <Grid size={1}>
      <Stack direction="column" alignItems="center">
        <Box width="95%" border={1}>
          <Typography {...ScoreProps} align="center">
            {score ? `${score.away} - ${score.home}` : "-"}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center">
          {isActive && <SportsFootballIcon color="error" fontSize="small" />}
          <Typography align="center">{period}</Typography>
        </Stack>
      </Stack>
    </Grid>
  );
}
