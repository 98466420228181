import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDialog({
  prompt,
  open,
  confirmText,
  cancelText,
  confirmFn,
  closeDialog,
}: {
  prompt: string;
  confirmText: string;
  cancelText: string;
  open: boolean;
  confirmFn: () => void;
  closeDialog: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">{prompt}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{cancelText}</Button>
        <Button onClick={confirmFn}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
}
