import React, { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { SquaresEntryData, SquaresGameData } from "@sportsball/shared";

import { useUser } from "../context/useUser";

export function JoinSquaresGame({
  gameAndId,
  setEntry,
}: {
  gameAndId: { gameId: string; game: SquaresGameData };
  // eslint-disable-next-line no-unused-vars
  setEntry: (entry: SquaresEntryData | undefined) => void;
}) {
  const { user } = useUser();
  const { game } = gameAndId;
  const [initials, setInitials] = useState(user?.prefs?.preferredInitials ?? "");

  const lowerInitials = initials.toLowerCase();
  const okInitials =
    lowerInitials.length !== 0 &&
    Object.values(game.entries ?? {}).every((entry) => entry.initials.toLowerCase() !== lowerInitials);

  useEffect(() => {
    setEntry(okInitials ? { initials } : undefined);
  }, [initials, okInitials, setEntry]);

  function handleInitialsChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInitials(event.target.value.trim());
  }

  return (
    <>
      {game.description && <Typography variant="body1">{game.description}</Typography>}
      <Stack spacing={2} p={2}>
        <p>To join the {game.name} squares game, provide your initials that you will use to pick your squares.</p>
        <TextField id="initials" label="Your initials" defaultValue={initials} onChange={handleInitialsChange} />
      </Stack>
    </>
  );
}
