import { useCallback } from "react";

import { SomeGameData } from "@sportsball/shared";

import { useFirestoreQuery } from "./firestoreQuery";
import { LazyFirebase } from "../context/useFirebasePreUser";

export function useUserGames() {
  const runningGamesWhereClause = useCallback(
    ({ firebase, uid }: { firebase: LazyFirebase; uid: string }) => [firebase.firestorePackage.where("uid", "==", uid)],
    []
  );
  const runningGames = useFirestoreQuery({
    queryKey: "runningGames",
    collectionName: "games",
    whereClause: runningGamesWhereClause,
    schema: SomeGameData,
  });

  const enteredGamesWhereClause = useCallback(
    ({ firebase, uid }: { firebase: LazyFirebase; uid: string }) => [
      firebase.firestorePackage.where(`entries.${uid}`, "!=", null),
    ],
    []
  );
  const enteredGames = useFirestoreQuery({
    queryKey: "enteredGames",
    collectionName: "games",
    whereClause: enteredGamesWhereClause,
    schema: SomeGameData,
  });

  const isLoading = runningGames.isLoading || enteredGames.isLoading;
  const error = runningGames.error || enteredGames.error;

  return { runningGames: runningGames.data, enteredGames: enteredGames.data, isLoading, error };
}

export function findGame(gameId: string, userGames: ReturnType<typeof useUserGames>) {
  return (
    userGames.runningGames?.find((game) => game.id === gameId) ||
    userGames.enteredGames?.find((game) => game.id === gameId)
  )?.data;
}
