import { format } from "date-fns";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveIcon from "@mui/icons-material/Remove";

import { FootballGame, isGameOff, PickemAnswer, PickemQuestion, QuestionType, Side } from "@sportsball/shared";
import { isGameActive } from "@sportsball/shared";

import { NewPickemGameData } from "../pages/NewGame";
import { ScoreProps } from "../style";
import BonusPointsCoin from "./BonusPointsCoin";

export function _getTiebreaker(game: NewPickemGameData, removedFootballGames?: Set<string>) {
  // model tiebreaker is only used as a boolean tiebreaker on/off. Will change to boolean in model.
  if (game.tiebreakerId === undefined) {
    return undefined;
  }
  const lastActiveQuestion = [...game.questions]
    .reverse()
    .find((question) => !removedFootballGames || !removedFootballGames.has(question.footballGameId));
  if (!lastActiveQuestion) {
    return undefined;
  }
  return game.footballGames[lastActiveQuestion.footballGameId];
}

function _matchupsPerDate({ questions, footballGames }: NewPickemGameData) {
  const matchupsPerDate: Record<string, [number, PickemQuestion][]> = {};
  for (let questionNumber = 0; questionNumber < questions.length; questionNumber++) {
    const matchup = questions[questionNumber];
    const footballGame = footballGames[matchup.footballGameId];
    const { date } = footballGame;
    matchupsPerDate[date] ||= [];
    matchupsPerDate[date].push([questionNumber, matchup]);
  }
  return matchupsPerDate;
}

export default function PickemSheet({
  game,
  picks,
  results,
  tiebreakerPoints,
  makePick,
  removedFootballGames,
  toggleFootballGames,
  tweakMoneylinePoints,
  setTiebreakerPoints,
}: {
  game: NewPickemGameData;
  picks?: Partial<Record<string, PickemAnswer>>;
  results?: Partial<Record<string, PickemAnswer>>;
  tiebreakerPoints?: number;
  // eslint-disable-next-line no-unused-vars
  makePick?: (args: { questionNumber: number; pick: Side }) => void;
  removedFootballGames?: Set<string>;
  // eslint-disable-next-line no-unused-vars
  toggleFootballGames?: (footballGameIds: string[]) => void;
  // eslint-disable-next-line no-unused-vars
  tweakMoneylinePoints?: (footballGameId: string, points: number) => void;
  // eslint-disable-next-line no-unused-vars
  setTiebreakerPoints?: (points: number) => void;
}) {
  const { footballGames } = game;
  function GameStatus({ footballGame }: { footballGame: FootballGame }) {
    switch (footballGame.status) {
      case "NS":
        return (
          <Typography variant="body2" noWrap>
            {format(footballGame.startTimestamp, "h:mm aa")}
          </Typography>
        );
      case "Q1":
      case "Q2":
      case "Q3":
      case "Q4":
      case "OT":
        return (
          <Typography variant="body2">
            {footballGame.status} {footballGame.timer}
          </Typography>
        );
      case "HT":
        return <Typography variant="body2">Half</Typography>;
      case "FT":
        return <Typography variant="body2">Final</Typography>;
      case "AOT":
        return <Typography variant="body2">F/OT</Typography>;
      case "CANC":
        return <Typography variant="body2">Canceled</Typography>;
      case "PST":
        return <Typography variant="body2">PPD</Typography>;
    }

    return <Typography variant="body2">{footballGame.status}</Typography>;
  }

  function GameRow({ questionNumber, matchup }: { questionNumber: number; matchup: PickemQuestion }) {
    const pick = picks?.[questionNumber.toString()];
    const result = results?.[questionNumber.toString()];
    const question = game.questions[questionNumber];
    const footballGame = footballGames[matchup.footballGameId];
    const { scores, teams } = footballGame;
    const questionWinProbability = game.footballGameWinProbabilities?.[matchup.footballGameId];
    const winningSide = !result
      ? undefined
      : {
          [PickemAnswer.enum.Away]: Side.enum.Away,
          [PickemAnswer.enum.Home]: Side.enum.Home,
          [PickemAnswer.enum.Off]: undefined,
          [PickemAnswer.enum.Push]: undefined,
        }[result];

    function sideColor(side: Side) {
      if (!winningSide || side !== pick) {
        return;
      }
      if (side === winningSide) {
        return "lightgreen";
      }
      if (result === PickemAnswer.enum.Off) {
        return "lightgrey";
      }
      if (result === PickemAnswer.enum.Push) {
        return;
      }
      return "lightcoral";
    }

    function teamName(side: "away" | "home") {
      const team = side === "away" ? teams.away : teams.home;

      const parts = [team.name];
      if (team.rank) {
        parts.push(`(#${team.rank})`);
      }
      if (question.type === QuestionType.enum.VsSpreadPick) {
        const { vsSpread } = question;
        if (side === "away") {
          if (vsSpread !== "OFF" && vsSpread < 0) {
            parts.push(`+${-vsSpread}`);
          }
        } else {
          if (vsSpread === "OFF") {
            parts.push("OFF");
          } else if (vsSpread === 0) {
            parts.push("EVEN");
          } else if (vsSpread >= 0) {
            parts.push(`+${vsSpread}`);
          }
        }
      }

      return parts.join(" ");
    }

    const gameActive = isGameActive(footballGame);
    const checkboxName = questionNumber.toString();
    const moneylinePoints = matchup.type === QuestionType.enum.MoneylinePick ? matchup.moneylinePoints : undefined;
    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" width="100%">
          <Stack direction="row" width="100%" paddingRight={1} bgcolor={sideColor(Side.enum.Away)} alignItems="center">
            <Checkbox
              name={checkboxName}
              disabled={isGameOff(matchup)}
              checked={pick === PickemAnswer.enum.Away}
              onChange={() => makePick?.({ questionNumber, pick: Side.enum.Away })}
            />
            <Stack direction="row" flexGrow={1} spacing={0.5}>
              <Typography>{teamName("away")}</Typography>
              {moneylinePoints && <BonusPointsCoin bonusPoints={moneylinePoints.away} />}
              {gameActive && pick === PickemAnswer.enum.Away && questionWinProbability && (
                <Typography sx={{ mr: 1 }}>{Math.round((1 - questionWinProbability) * 100)}%</Typography>
              )}
            </Stack>
            {scores && <Typography {...ScoreProps}>{scores.total.away}</Typography>}
          </Stack>
          <Stack direction="row" width="100%" paddingRight={1} bgcolor={sideColor(Side.enum.Home)} alignItems="center">
            <Checkbox
              name={checkboxName}
              disabled={isGameOff(matchup)}
              checked={pick === PickemAnswer.enum.Home}
              onChange={() => makePick?.({ questionNumber, pick: Side.enum.Home })}
            />{" "}
            <Stack direction="row" flexGrow={1} spacing={0.5}>
              <Typography>{teamName("home")}</Typography>
              {moneylinePoints && <BonusPointsCoin bonusPoints={moneylinePoints.home} />}
              {gameActive && pick === PickemAnswer.enum.Home && questionWinProbability && (
                <Typography sx={{ mr: 1 }}>{Math.round(questionWinProbability * 100)}%</Typography>
              )}
            </Stack>
            {scores && <Typography {...ScoreProps}>{scores.total.home}</Typography>}
          </Stack>
        </Stack>
        <Box padding={2} alignContent="center" borderLeft={1}>
          <GameStatus footballGame={game.footballGames[matchup.footballGameId]} />
        </Box>
      </Stack>
    );
  }

  function DayToggle({ matchups }: { matchups: [number, PickemQuestion][] }) {
    if (!toggleFootballGames || !removedFootballGames) {
      return <></>;
    }
    const partiallyRemoved = matchups.some((matchupPair) => removedFootballGames.has(matchupPair[1].footballGameId));

    function toggleDay() {
      if (!toggleFootballGames || !removedFootballGames) {
        throw new Error("toggleFootballGames or removedFootballGames is undefined");
      }
      // if any game is already removed we are toggling on
      const togglingOn = partiallyRemoved;
      const toggleFootballGameIds = matchups
        .map((matchupPair) => matchupPair[1].footballGameId)
        .filter((footballGameId) => removedFootballGames.has(footballGameId) === togglingOn);
      toggleFootballGames(toggleFootballGameIds);
    }
    return (
      <IconButton onClick={() => toggleDay()}>
        {partiallyRemoved ? <AddCircleIcon color="success" /> : <RemoveCircleIcon />}
      </IconButton>
    );
  }

  function GameToggle({ footballGameId }: { footballGameId: string }) {
    if (!toggleFootballGames || !removedFootballGames) {
      return <></>;
    }
    return (
      <IconButton onClick={() => toggleFootballGames([footballGameId])}>
        {removedFootballGames.has(footballGameId) ? <AddCircleIcon color="success" /> : <RemoveCircleIcon />}
      </IconButton>
    );
  }

  function DayGames({ date, matchups }: { date: string; matchups: [number, PickemQuestion][] }) {
    const localDate = new Date(date + "T00:00:00"); // Appending time to ensure it is treated as local
    const formattedDate = format(localDate, "EEEE, MMM d");

    function boxColor(footballGameId: string) {
      if (removedFootballGames?.has(footballGameId)) {
        return "lightgrey";
      }
      const footballGame = game.footballGames[footballGameId];
      if (isGameActive(footballGame)) {
        return "white";
      }
      return undefined;
    }

    return (
      <>
        <Box paddingY={2}>
          <Stack direction="row" bgcolor="lightblue" padding={1} alignItems="center">
            <Typography variant="h6">{formattedDate}</Typography>
            <DayToggle matchups={matchups} />
          </Stack>
        </Box>
        <Box
          sx={{
            columns: { xs: 1, sm: 2, md: 3, lg: 4 }, // number of columns
            columnGap: 1, // gap between columns
          }}
        >
          {matchups.map(([questionNumber, matchup], i) => (
            <Stack key={i} direction="row" sx={{ pageBreakInside: "avoid", breakInside: "avoid" }}>
              {matchup.type === QuestionType.enum.MoneylinePick && tweakMoneylinePoints && (
                <Stack direction="column" justifyContent="center" alignItems="center">
                  <Button onClick={() => tweakMoneylinePoints?.(matchup.footballGameId, 0.1)}>
                    <AddIcon /> <BonusPointsCoin bonusPoints={0.1} />
                  </Button>
                  <Button onClick={() => tweakMoneylinePoints?.(matchup.footballGameId, -0.1)}>
                    <RemoveIcon /> <BonusPointsCoin bonusPoints={0.1} />
                  </Button>
                </Stack>
              )}
              <Box width="100%" bgcolor={boxColor(matchup.footballGameId)} marginBottom={1} border={1}>
                <GameRow key={questionNumber} questionNumber={questionNumber} matchup={matchup} />
              </Box>
              <GameToggle footballGameId={matchup.footballGameId} />
            </Stack>
          ))}
        </Box>
      </>
    );
  }
  const matchupsPerDate = _matchupsPerDate(game);
  const tiebreaker = _getTiebreaker(game, removedFootballGames);

  return (
    <Stack>
      {Object.entries(matchupsPerDate).map(([date, matchups]) => (
        <DayGames key={date} date={date} matchups={matchups} />
      ))}
      {tiebreaker !== undefined && (
        <>
          <Box paddingY={2}>
            <Typography variant="h6" bgcolor="lightblue" padding={1}>
              Tiebreaker
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" padding={1}>
            <Typography marginRight={1}>
              Guess total score of {tiebreaker.teams.away.name} @ {tiebreaker.teams.home.name} Game
            </Typography>
            {setTiebreakerPoints && (
              <TextField
                type="number"
                defaultValue={tiebreakerPoints}
                inputProps={{ min: 0, max: 100 }}
                onChange={(e) => setTiebreakerPoints(parseInt(e.target.value))}
              />
            )}
            {!setTiebreakerPoints && <Typography>{tiebreakerPoints}</Typography>}
          </Stack>
        </>
      )}
    </Stack>
  );
}
