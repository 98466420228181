import Grid from "@mui/material/Grid2";

import { FootballGame, SquaresPeriod } from "@sportsball/shared";

import { getActivePeriod } from "./SquaresBoard";
import { getPeriodScores, PeriodScore } from "./PeriodScore";

const _periods = [SquaresPeriod.enum.Q1, SquaresPeriod.enum.Half, SquaresPeriod.enum.Q3, SquaresPeriod.enum.Final];

export function SquaresScoreboard({ footballGame }: { footballGame: FootballGame }) {
  const periodScores = getPeriodScores(footballGame);
  const activePeriod = getActivePeriod(footballGame);
  return (
    <Grid container columns={4} width="100%">
      {_periods.map((period) => (
        <PeriodScore key={period} period={period} score={periodScores[period]} isActive={period === activePeriod} />
      ))}
    </Grid>
  );
}
