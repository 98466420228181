// Footer.tsx
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";

import Facebook from "@mui/icons-material/Facebook";
import Twitter from "@mui/icons-material/Twitter";
import Instagram from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        p: { xs: 2, sm: 4 }, // Responsive padding
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        {/* Logo and Description */}
        <Grid>
          <Typography variant="h6" sx={{ mb: 1 }} fontFamily="Nico Moji">
            Sportsball
          </Typography>
          <Typography variant="body2">Create online games and connect with friends anytime, anywhere!</Typography>
        </Grid>

        {/* Navigation Links */}
        <Grid>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Quick Links
          </Typography>
          <Box>
            <Link href="/" color="inherit" underline="none" sx={{ display: "block", mb: 0.5 }}>
              Home
            </Link>
            <Link
              href="/football-pools/pickem/online/new"
              color="inherit"
              underline="none"
              sx={{ display: "block", mb: 0.5 }}
            >
              Create your own Pick&apos;em Pool
            </Link>
            <Link
              href="/football-pools/squares/online/new"
              color="inherit"
              underline="none"
              sx={{ display: "block", mb: 0.5 }}
            >
              Create your own Squares Game
            </Link>
          </Box>
        </Grid>

        {/* Social Media Links */}
        <Grid sx={{ textAlign: { xs: "center", sm: "right" } }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Follow Us
          </Typography>
          <Box>
            <IconButton color="inherit" href="https://facebook.com">
              <Facebook />
            </IconButton>
            <IconButton color="inherit" href="https://twitter.com">
              <Twitter />
            </IconButton>
            <IconButton color="inherit" href="https://instagram.com">
              <Instagram />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="body2">&copy; {new Date().getFullYear()} Sportsball. All rights reserved.</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
