import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import deepEqual from "deep-equal";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import CancelIcon from "@mui/icons-material/Cancel";

import { GameType, SomeEntryData, SquaresEntryData } from "@sportsball/shared";
import { isPickemGame, isSquaresGame } from "@sportsball/shared";

import { JoinSquaresGame } from "./JoinSquaresGame";
import { JoinPickemGame } from "./JoinPickemGame";
import { maybeUserUid, useUser } from "../context/useUser";
import { GameContainerProps } from "../components/GameContainer";

export default function JoinGame() {
  const navigate = useNavigate();
  const { game, gameId } = useOutletContext<GameContainerProps>();
  const { firebase, user } = useUser();
  const [joinEntry, setJoinEntry] = useState<SomeEntryData | undefined>(undefined);

  if (!gameId) {
    throw new Error("Missing gameId");
  }

  function handleJoin() {
    if (!joinEntry) {
      throw new Error("Missing required data");
    }
    const uid = maybeUserUid(user);
    (async () => {
      if (!gameId || !firebase) {
        return;
      }
      const { doc, updateDoc } = firebase.firestorePackage;
      const gameRef = doc(firebase.firestore, `/games/${gameId}`);
      await updateDoc(gameRef, { [`entries.${uid}`]: joinEntry });
      navigate(`/games/${gameId}`);
    })().catch((e: any) => {
      alert("Error submitting entry" + e);
    });
  }

  function joinButtonText() {
    switch (game.type) {
      case GameType.enum.Squares:
        return "Join Squares Game";
      case GameType.enum.Pickem:
        return "Submit Entry";
      default:
        return "Join Game";
    }
  }

  function safeSetJoinEntry(entry: SomeEntryData | undefined) {
    if (joinEntry && deepEqual(joinEntry, entry)) {
      return;
    }
    setJoinEntry(entry);
  }

  const name = game.name ?? (isSquaresGame(game) ? "Squares Game" : "Pick'em Pool");
  return (
    <Stack spacing={2}>
      <h1>Join {name}</h1>
      {isSquaresGame(game) && (
        <JoinSquaresGame
          gameAndId={{ game, gameId }}
          setEntry={(entry: SquaresEntryData | undefined) => safeSetJoinEntry(entry)}
        />
      )}
      {isPickemGame(game) && (
        <JoinPickemGame
          gameAndId={{ game, gameId }}
          setEntry={(entry: SomeEntryData | undefined) => safeSetJoinEntry(entry)}
        />
      )}
      <Stack padding={4} spacing={2} direction="row" justifyContent="center">
        <Button variant="contained" onClick={handleJoin} disabled={!joinEntry}>
          {joinButtonText()}
        </Button>
        <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => navigate(`/games/${gameId}`)}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
