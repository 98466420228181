import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import type { DocumentData } from "firebase/firestore";
import { useUser } from "../context/useUser";

export function useLoggedInFirestoreObject<T>(
  path: string,
  schema: z.ZodSchema<T>,
  initialData?: T
): {
  object: T | undefined;
  error: Error | undefined;
  loading: boolean;
} {
  const { firebase, user } = useUser();
  const [object, setObject] = useState(initialData);
  const [loading, setLoading] = useState(!initialData);
  const [error, setError] = useState<Error | undefined>(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- any versions of `schema` would be interchangeable
  const safeParse = useCallback((data: DocumentData | undefined) => schema.safeParse(data), []);

  useEffect(() => {
    // wait for logged for a Logged In Firestore Object. Firestore permissions are set up to
    // always require a user to be logged in (even just anonymously).
    if (!firebase || !user) {
      return;
    }

    const { doc, onSnapshot } = firebase.firestorePackage;
    const docRef = doc(firebase.firestore, path);
    return onSnapshot(
      docRef,
      (snapshot) => {
        setLoading(false);

        if (!snapshot.exists()) {
          setObject(undefined);
          return;
        }
        // doc.data returns null but we want undefined for z.optional schema
        const result = safeParse(snapshot.data());
        if (!result.success) {
          setObject(undefined);
          setError(result.error);
        } else {
          // TODO: VERY strange case here where our entry tiebreaker data comes out undefined when it comes
          // out of zod parsing. Go back to doc.data() as T for now :/
          setObject(snapshot.data() as T);
          setError(undefined);
        }
      },
      (error) => {
        setObject(undefined);
        setError(error);
        setLoading(false);
      }
    );
  }, [user, path, safeParse, firebase]);

  return { object, error, loading };
}

export function useLoggedInDatabaseObject<T>(
  path: string,
  schema: z.ZodSchema<T>,
  initialData?: T
): {
  object: T | undefined;
  error: Error | undefined;
  loading: boolean;
} {
  const { firebase, user } = useUser();
  const [object, setObject] = useState(initialData);
  const [loading, setLoading] = useState(!initialData);
  const [error, setError] = useState<Error | undefined>(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- any versions of `schema` would be interchangeable
  const safeParse = useCallback((data: DocumentData | undefined) => schema.safeParse(data), []);

  useEffect(() => {
    // wait for logged for a Logged In Firestore Object. Firestore permissions are set up to
    // always require a user to be logged in (even just anonymously).
    if (!firebase || !user) {
      return;
    }
    const { onValue, ref } = firebase.databasePackage;
    const docRef = ref(firebase.database, path);
    return onValue(
      docRef,
      (snapshot) => {
        setLoading(false);
        if (!snapshot.exists()) {
          setObject(undefined);
          return;
        }
        const result = safeParse(snapshot.val() as DocumentData);
        if (!result.success) {
          setObject(undefined);
          setError(result.error);
        } else {
          // TODO: VERY strange case here where our entry tiebreaker data comes out undefined when it comes
          // out of zod parsing. Go back to doc.data() as T for now :/
          setObject(result.data);
          setError(undefined);
        }
      },
      (error) => {
        setObject(undefined);
        setError(error);
        setLoading(false);
      }
    );
  }, [user, path, safeParse, firebase]);

  return { object, error, loading };
}
