import { z } from "zod";
import { LazyFirebase } from "../context/useFirebasePreUser";

export async function safeSet<T extends { [x: string]: unknown }>(
  path: string,
  data: T,
  schema: z.ZodSchema<T>,
  lazyFirebase: LazyFirebase
) {
  const result = schema.safeParse(data);
  if (!result.success) {
    throw result.error;
  }
  const { doc, setDoc } = lazyFirebase.firestorePackage;
  await setDoc(doc(lazyFirebase.firestore, path), data);
}
