import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import IosShareIcon from "@mui/icons-material/IosShare";

import { GameType } from "@sportsball/shared";
import { getGameStartTimestamp, isPickemGame, isSquaresGame } from "@sportsball/shared";

import QuestionsGame from "./QuestionsGame";
import SquaresGame from "./SquaresGame";

import Countdown from "../components/Countdown";
import GameTitle from "../components/GameTitle";
import { maybeUserUid, useUser } from "../context/useUser";
import ConfirmDialog from "../components/ConfirmDialog";
import ShareDialog from "../components/ShareDialog";
import { GameContainerProps } from "../components/GameContainer";

export default function Game() {
  const navigate = useNavigate();
  const { game, gameId, gameOn } = useOutletContext<GameContainerProps>();
  const { firebase, user } = useUser();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const uid = maybeUserUid(user);

  let gameLocked = false;
  if (isSquaresGame(game)) {
    gameLocked = game.targetScores !== undefined || Object.keys(game.entries || {}).length >= game.size;
  } else {
    gameLocked = gameOn;
  }

  const hasEntry = uid && game.entries && game.entries[uid];

  function performDelete() {
    if (!firebase) {
      return;
    }
    (async () => {
      const { deleteDoc, doc } = firebase.firestorePackage;
      const gameRef = doc(firebase.firestore, `games/${gameId}`);
      await deleteDoc(gameRef);
      setShowDeleteDialog(false);
      navigate(`/`);
    })().catch(alert);
  }

  const startTimestamp = getGameStartTimestamp(game);
  return (
    <>
      <Stack spacing={2}>
        <GameTitle gameAndId={{ data: game, id: gameId }} navButtons={true} />
        {game.type === GameType.enum.Squares && game.payouts && (
          <Typography>
            Payouts: Q1: ${game.payouts.Q1} | Half: ${game.payouts.Half} | Q3: ${game.payouts.Q3} | Final: $
            {game.payouts.Final}
          </Typography>
        )}
        {game.description && <Typography variant="body1">{game.description}</Typography>}
        {!gameOn && startTimestamp && <Countdown startTimestamp={startTimestamp} />}
        {isPickemGame(game) && <QuestionsGame gameId={gameId} game={game} gameOn={gameOn} />}
        {isSquaresGame(game) && <SquaresGame gameId={gameId} game={game} />}
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button variant="outlined" startIcon={<HomeIcon />} onClick={() => navigate("/")}>
            Home
          </Button>
          {!hasEntry && !gameLocked && (
            <Button variant="contained" onClick={() => navigate("join")}>
              Join Game
            </Button>
          )}
          <Button variant="contained" startIcon={<IosShareIcon />} onClick={() => setShowShareDialog(true)}>
            Share
          </Button>
          {game.uid === uid && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setShowDeleteDialog(true)}
            >
              Delete Game
            </Button>
          )}
        </Stack>
      </Stack>
      <ConfirmDialog
        prompt="Are you sure you want to delete this game?"
        confirmText="Delete"
        cancelText="Cancel"
        open={showDeleteDialog}
        confirmFn={performDelete}
        closeDialog={() => setShowDeleteDialog(false)}
      />
      <ShareDialog gameId={gameId} game={game} open={showShareDialog} closeDialog={() => setShowShareDialog(false)} />
    </>
  );
}
