import { ReactElement } from "react";

import { TakeoverAccountParameters, TakeoverAccountResponse } from "@sportsball/shared";

import { circularProgress } from "../main";
import { z } from "zod";
import { LazyFirebase } from "../context/useFirebasePreUser";

export function processError({ loading, error }: { loading: boolean; error: Error | undefined }): ReactElement {
  if (loading) {
    // center loading spinner on the page verically and horizontally
    return circularProgress();
  }
  if (error) {
    alert("Parsing Error");
    return <p>{error.message}</p>;
  }
  return <div />;
}

export async function generateTakeoverCode(firebase: LazyFirebase) {
  const { httpsCallable } = firebase.functionsPackage;
  const generateTakeoverCodeFn = httpsCallable<void, { takeoverCode: string }>(
    firebase.functions,
    "generateTakeoverCode"
  );
  const response = await generateTakeoverCodeFn();
  const payload = z.object({ takeoverCode: z.string() }).parse(response.data);
  return payload.takeoverCode;
}

export async function completeTakeover({ takeoverCode, firebase }: { takeoverCode: string; firebase: LazyFirebase }) {
  const { httpsCallable } = firebase.functionsPackage;
  const takeoverAccountFn = httpsCallable<TakeoverAccountParameters, TakeoverAccountResponse>(
    firebase.functions,
    "takeoverAccount"
  );
  const response = await takeoverAccountFn({ takeoverCode });
  const { gamesClobbered } = TakeoverAccountResponse.parse(response.data);

  if (gamesClobbered) {
    alert(
      "Entries created while you were signed out have been removed where the conflicted with your existing entries."
    );
  }
}
