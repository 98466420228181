export function calculateDogMoneylineScore({
  favoriteOdds,
  dogOdds,
  favoritePoints,
}: {
  favoriteOdds: number;
  dogOdds: number;
  favoritePoints: number;
}) {
  const favoriteReturn = favoriteOdds - 1;
  const dogReturn = dogOdds - 1;
  const factor = favoritePoints / favoriteReturn; // see what it takes to normalize the favorite to 1.0
  const score = Math.round(factor * dogReturn * 10) / 10;
  if (score > 30) {
    return Math.round(score / 5) * 5;
  }
  if (score > 10) {
    return Math.round(score);
  }
  return score;
}

export function calculateMoneylineScoring(
  { home: homeOdds, away: awayOdds }: { home: number; away: number },
  { favoritePoints }: { favoritePoints: number }
) {
  if (awayOdds > homeOdds) {
    return {
      home: favoritePoints,
      away: calculateDogMoneylineScore({ favoriteOdds: homeOdds, dogOdds: awayOdds, favoritePoints }),
    };
  }
  return {
    away: favoritePoints,
    home: calculateDogMoneylineScore({ favoriteOdds: awayOdds, dogOdds: homeOdds, favoritePoints }),
  };
}
