import { Fragment, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ScheduledGame, SomeGameData } from "@sportsball/shared";
import { TeamSlate, TeamSlateShortNames } from "@sportsball/shared";

import GameCard from "./components/GameCard";
import { LazyFirebase } from "./context/useFirebasePreUser";
import { maybeUserUid, useUser } from "./context/useUser";
import { useSpecials } from "./context/useSpecials";
import { useUserGames } from "./hooks/userGamesHook";

export type GameAndId = { id: string; data: SomeGameData };

export function searchByCode(
  code: string | undefined,
  // eslint-disable-next-line no-unused-vars
  setGameAndId: (gameAndId: GameAndId | undefined) => void,
  firebase: LazyFirebase | undefined
) {
  if (!code || !firebase) {
    setGameAndId(undefined);
    return;
  }
  const { collection, onSnapshot, query, where } = firebase.firestorePackage;
  const gamesCollection = collection(firebase.firestore, "games");
  const q = query(gamesCollection, where("code", "==", code.toUpperCase()));
  return onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      setGameAndId(undefined);
    } else {
      snapshot.forEach((gameSnapshot) => {
        const result = SomeGameData.safeParse(gameSnapshot.data());
        if (!result.success) {
          setGameAndId(undefined);
          return;
        }
        setGameAndId({ id: gameSnapshot.id, data: result.data });
      });
    }
  });
}

export default function MyGames() {
  const navigate = useNavigate();
  const { firebase, user } = useUser();
  const [searchGameCode, setSearchGameCode] = useState<string | undefined>();
  const [searchGame, setSearchGame] = useState<GameAndId | undefined>();

  const specials = useSpecials();
  useEffect(() => searchByCode(searchGameCode, setSearchGame, firebase), [searchGameCode, firebase]);

  const uid = maybeUserUid(user);
  const { enteredGames, runningGames } = useUserGames();

  const gamesPlayingButNotRunning = (enteredGames ?? []).filter((gameAndId) => gameAndId.data.uid !== uid) ?? [];

  const nowTimestamp = Date.now();
  const bestSpecials: [string, ScheduledGame][] = [];
  for (const [key, specialOptions] of Object.entries(specials ?? {})) {
    for (const special of specialOptions) {
      if (special.startTimestamp > nowTimestamp) {
        bestSpecials.push([key, special]);
        break;
      }
    }
  }
  bestSpecials.sort(([, a], [, b]) => a.startTimestamp - b.startTimestamp);
  return (
    <>
      <h1>Play NFL and NCAA Football Squares and Pick&apos;em Pools with Friends</h1>
      <p>
        Setting up squares games or pick&apos;em pools for NFL and NCAA matchups is quick and easy on Sportsball, right
        from your phone. In just a few taps, invite friends, make picks, and enjoy the action—anytime, anywhere!
      </p>
      {Object.keys(runningGames ?? []).length > 0 && (
        <Stack spacing={2}>
          <h2>Games you are running</h2>
          {runningGames!.map((gameAndId, i) => (
            <GameCard key={i} gameAndId={gameAndId} />
          ))}
        </Stack>
      )}
      {Object.keys(gamesPlayingButNotRunning).length > 0 && (
        <Stack spacing={2}>
          <h2>Games you are playing</h2>
          {gamesPlayingButNotRunning.map((gameAndId, i) => (
            <GameCard key={i} gameAndId={gameAndId} />
          ))}
        </Stack>
      )}
      <h2>Create your own new game</h2>
      <p>
        Enjoying sportsball and want to create your own game? It&apos;s easy! Choose which type of game you wish to run:
      </p>
      <Box
        sx={{
          columns: { xs: 2, sm: 3, md: 4 }, // number of columns
          columnGap: 1, // gap between columns
        }}
      >
        {bestSpecials.map(([specialId, special]) => (
          <Box key={specialId} marginBottom={1}>
            <Link to={`/football-pools/squares/online/new/special/${specialId}`}>
              <Button variant="contained" fullWidth={true}>
                <Stack>
                  <Typography>{specialId} Squares</Typography>
                  {special.teams.away.name !== "Away" && (
                    <Typography>
                      {special.teams.away.name} @ {special.teams.home.name}
                    </Typography>
                  )}
                </Stack>
              </Button>
            </Link>
          </Box>
        ))}
        {Object.values(TeamSlate).map((teamSlate) => (
          <Fragment key={teamSlate}>
            <Box marginBottom={1}>
              <Link to={`/football-pools/squares/online/new/slate/${teamSlate}`}>
                <Button variant="contained" fullWidth={true}>
                  <Stack>
                    <Typography>{TeamSlateShortNames[teamSlate]} Squares</Typography>
                  </Stack>
                </Button>
              </Link>
            </Box>
            <Box marginBottom={1}>
              <Link to={`/football-pools/pickem/online/new/slate/${teamSlate}`}>
                <Button variant="contained" fullWidth={true}>
                  <Stack>
                    <Typography>{TeamSlateShortNames[teamSlate]} Pick&apos;em Pool</Typography>
                  </Stack>
                </Button>
              </Link>
            </Box>
          </Fragment>
        ))}
        <Box marginBottom={1}>
          <Button variant="contained" fullWidth={true} onClick={() => navigate("/football-pools/pickem/online/new")}>
            New Pick&apos;em Pool
          </Button>
        </Box>
      </Box>
      <h2>Join by game code</h2>
      <Stack spacing={2}>
        <p>Each game has a short code that allows others to join. Enter the game code here.</p>
        {searchGame && <GameCard gameAndId={searchGame} />}
        <Stack direction="row">
          <TextField
            id="searchGameCode"
            label="Enter Game Code"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
            onChange={(e) => setSearchGameCode(e.target.value.toUpperCase())}
          />
        </Stack>
      </Stack>
    </>
  );
}
