import { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { type User as FirebaseUser } from "@firebase/auth";

import { AnonPromotion, AvatarSet, UserPrefs } from "@sportsball/shared";

import { LazyFirebase, useFirebasePreUser } from "./useFirebasePreUser";
import { maybeUserUid, userIsSignedIn, UserContext } from "./useUser";
import { AvatarSetSizes } from "../avatar/AvatarIcon";

export interface User {
  firebaseUid: string;
  firebaseUserIsAnonymous: boolean;
  promotedUid?: string;
  prefs?: UserPrefs;
}

function _baseUserFromFirebaseUser(firebaseUser: FirebaseUser | null): User | undefined {
  if (!firebaseUser) {
    return undefined;
  }
  const { uid, isAnonymous } = firebaseUser;
  return { firebaseUid: uid, firebaseUserIsAnonymous: isAnonymous };
}

async function _maybeInitializeNewUserPrefs(firebaseUid: string, firebase: LazyFirebase) {
  const { collection, doc, getDoc, setDoc } = firebase.firestorePackage;
  const usersCollection = collection(firebase.firestore, "users");
  const userPrefsRef = doc(usersCollection, firebaseUid);

  const existingPrefs = await getDoc(userPrefsRef);
  if (existingPrefs.exists()) {
    return;
  }

  // create default user prefs
  const defaultAvatarSet: AvatarSet = "avatars-1";
  const avatar = {
    set: defaultAvatarSet,
    id: Math.floor(Math.random() * AvatarSetSizes[defaultAvatarSet]),
  };
  const newUserPrefs: UserPrefs = {
    preferredInitials: "",
    preferredScreenName: "",
    avatar,
  };

  await setDoc(userPrefsRef, newUserPrefs);
}

export default function UserProvider({ children }: { children: ReactNode }) {
  const firebase = useFirebasePreUser();
  const [baseUser, setBaseUser] = useState(
    !firebase ? undefined : _baseUserFromFirebaseUser(firebase.auth.currentUser)
  );
  const [promotedUid, setPromotedUid] = useState<string | undefined>();
  const [userPrefs, setUserPrefs] = useState<UserPrefs | undefined>();
  const [searchParams] = useSearchParams();

  const urlPromotedUid = searchParams.get("promotedUid");
  const user = baseUser ? { ...baseUser, promotedUid, prefs: userPrefs } : undefined;
  const uid = maybeUserUid(user);
  const isSignedIn = user && userIsSignedIn(user);

  useEffect(() => {
    if (urlPromotedUid && !isSignedIn) {
      setPromotedUid(urlPromotedUid);
    }
  }, [urlPromotedUid, isSignedIn]);

  // keep user up to date and kick off anonymous sign-in for very first time in (user === null)
  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onAuthStateChanged, signInAnonymously } = firebase.authPackage;
    return onAuthStateChanged(firebase.auth, (firebaseUser) => {
      const user = _baseUserFromFirebaseUser(firebaseUser);
      setBaseUser(user);
      setPromotedUid(undefined);
      setUserPrefs(undefined);
      if (!user) {
        signInAnonymously(firebase.auth).catch(alert);
      } else if (!user.firebaseUserIsAnonymous) {
        // for actually signed in users (not anonymous users with promoted Uid) check
        // if user prefs exist and initialize them if not
        _maybeInitializeNewUserPrefs(user.firebaseUid, firebase).catch(alert);
      }
    });
  }, [firebase]);

  // for all signed in users load user prefs
  useEffect(() => {
    if (!uid || !isSignedIn || !firebase) {
      return;
    }
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userPrefsRef = doc(usersCollection, uid);
    return onSnapshot(userPrefsRef, (snapshot) => {
      if (!snapshot.exists()) {
        setUserPrefs(undefined);
        return;
      }
      const result = UserPrefs.safeParse(snapshot.data());
      if (!result.success) {
        alert("Failed to parse user data");
      } else {
        setUserPrefs(result.data);
      }
    });
  }, [uid, isSignedIn, firebase]);

  // for anonymous users watch for promotion
  const { firebaseUid, firebaseUserIsAnonymous } = baseUser || {};
  useEffect(() => {
    if (!firebaseUid || !firebaseUserIsAnonymous || !firebase) {
      return;
    }
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const anonPromotions = collection(firebase.firestore, "anon-promotions");
    const myAnonPromotion = doc(anonPromotions, firebaseUid);
    return onSnapshot(myAnonPromotion, (doc) => {
      if (!doc.exists()) {
        setPromotedUid(undefined);
        setUserPrefs(undefined);
        return;
      }
      const result = AnonPromotion.safeParse(doc.data());
      if (!result.success) {
        alert("Failed to parse anon promotion data");
      } else {
        const { promotedToUid } = result.data;
        setPromotedUid(promotedToUid);
      }
    });
  }, [firebaseUid, firebaseUserIsAnonymous, firebase]);

  return (
    <UserContext.Provider value={firebase && user ? { firebase, user } : { firebase: undefined, user: undefined }}>
      {children}
    </UserContext.Provider>
  );
}
