import React, { ReactNode, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import ShortUniqueId from "short-unique-id";

import { FootballLeague, GameType, PickemGameData, SomeGameData, SquaresGameData } from "@sportsball/shared";
import { safeSet } from "../data/safeSet";
import { generateShortCode, SpecialLongNames, TeamSlate, TeamSlateLongNames } from "@sportsball/shared";
import { Helmet } from "react-helmet-async";
import { userIsSignedIn, userUid, useUser } from "../context/useUser";
import SignInDialog from "../components/SignInDialog";

export type NewPickemGameData = Omit<PickemGameData, "uid" | "code" | "name" | "description">;
export type NewSquaresGameData = Omit<SquaresGameData, "uid" | "code" | "name" | "description">;
export type NewGameData = NewSquaresGameData | NewPickemGameData;

export default function NewGame({
  type,
  newGameData,
  children,
  singleGameFocused,
}: {
  type: GameType;
  newGameData: NewGameData | undefined;
  children: ReactNode;
  singleGameFocused: boolean;
}) {
  const navigate = useNavigate();
  const { teamSlate, specialId, leagueId } = useParams() as {
    teamSlate?: TeamSlate;
    specialId?: string;
    leagueId?: FootballLeague;
  };
  const { firebase, user } = useUser();
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);

  const [gameName, setGameName] = useState<string>("");
  const [gameDescription, setGameDescription] = useState<string>("");

  const { hash } = window.location;
  const gameType = type ?? (hash === "#new-squares" ? GameType.enum.Squares : GameType.enum.Pickem);

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    if (!user || !firebase) {
      throw new Error("Cannot submit form");
    }
    event.preventDefault();
    if (!newGameData) {
      throw new Error("Cannot submit form");
    }

    const gameData = {
      ...newGameData,
      uid: userUid(user),
      code: generateShortCode(),
      name: gameName,
      description: gameDescription,
    };

    const shortUniqueId = new ShortUniqueId();
    const gameId = shortUniqueId.rnd();
    (async () => {
      await safeSet(`games/${gameId}`, gameData, SomeGameData, firebase);
      navigate(`/games/${gameId}`);
    })().catch((e) => {
      console.error(e);
      alert(e);
    });
  }

  const gameTypeName = gameType === GameType.enum.Pickem ? "pick'em" : "squares";
  const gameTypeSlug = gameType === GameType.enum.Pickem ? "pickem" : "squares";
  const isSignedIn = user && userIsSignedIn(user);

  const description = {
    [GameType.enum.Pickem]:
      "Pick'em pools are a fun way to compete against your friends to see has the best ability to predict the results of NFL or NCAA football games. Choose your slate games and game type below and have fun!",
    [GameType.enum.Squares]:
      "Squares pools are a fun way to compete against your friends to see who can pick the correct scores for each quarter of an NFL or NCAA football game. Choose your game and board size below and have fun!",
  };

  let longName = "NFL or NCAA football";
  if (teamSlate) {
    longName = TeamSlateLongNames[teamSlate];
  } else if (leagueId) {
    longName = `${leagueId} football`;
  } else if (specialId) {
    longName = SpecialLongNames[specialId];
  }

  return (
    <>
      <Helmet>
        <title>{`Create a new ${longName} ${gameTypeName} pool | Sportsball`}</title>
        <meta
          name="description"
          content={`Sportsball is a place to play NFL and NCAA pick'em and squares pools with friends online. Here you can quickly start your own ${longName} ${gameTypeName} pool and invite your friends to play.`}
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        {singleGameFocused && (
          <Link to={`/football-pools/${gameTypeSlug}/online/new`}>Choose another NFL or NCAA Game</Link>
        )}
        <Stack spacing={2}>
          <Stack spacing={2} sx={{ borderRadius: 4, bgcolor: "white", padding: 2 }}>
            <h1>{`Create a new ${longName} ${gameTypeName}`}</h1>
            <p>{description[gameType]}</p>
            <p>Name your game.</p>
            <TextField
              id="game-name"
              label="Game Name"
              variant="outlined"
              onChange={(e) => setGameName(e.target.value)}
            />
            <p>Include a description of special rules for your game.</p>
            <TextField
              multiline
              rows={2}
              fullWidth={true}
              id="game-description"
              label="Game Description"
              variant="outlined"
              onChange={(e) => setGameDescription(e.target.value)}
            />
          </Stack>

          {children}

          <Stack direction="row" justifyContent="center" spacing={2}>
            {!isSignedIn && (
              <Button variant="contained" onClick={() => setSignInDialogOpen(true)}>
                Sign In to Create Game
              </Button>
            )}
            <Button
              type="submit"
              variant={newGameData ? "contained" : "outlined"}
              disabled={!newGameData || !isSignedIn}
            >
              Create Game
            </Button>
          </Stack>
        </Stack>
      </form>
      {firebase && <SignInDialog open={signInDialogOpen} closeDialog={() => setSignInDialogOpen(false)} />}
    </>
  );
}
