import { useEffect } from "react";

import { ServiceStatus } from "@sportsball/shared";

import { useUser } from "../context/useUser";
import { completeTakeover } from "../data/webUtils";

function _serviceVersionsCompatible(a: string, b: string): boolean {
  const [aMajor, aMinor] = a.split(".");
  const [bMajor, bMinor] = b.split(".");
  return aMajor === bMajor && aMinor === bMinor;
}

export default function UserServices({
  serviceVersion,
  setServiceVersion,
}: {
  serviceVersion: string | undefined;
  // eslint-disable-next-line no-unused-vars
  setServiceVersion: (version: string) => void;
}) {
  const { firebase, user } = useUser();
  const { firebaseUid } = user || {};

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const takeoverCode = window.localStorage.getItem("takeoverCode");
    const takeoverUid = window.localStorage.getItem("takeoverUid");

    if (!takeoverCode || !takeoverUid || firebaseUid === takeoverUid) {
      return;
    }
    window.localStorage.removeItem("takeoverCode");
    window.localStorage.removeItem("takeoverUid");
    completeTakeover({ takeoverCode, firebase }).catch(alert);
  }, [firebaseUid, firebase]);

  useEffect(() => {
    if (!user || !firebase) {
      return;
    }

    const { onValue, ref } = firebase.databasePackage;
    const serviceStatusRef = ref(firebase.database, "status/service");
    return onValue(serviceStatusRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }
      const { version: clientVersion } = ServiceStatus.parse(snapshot.val());
      if (!clientVersion) {
        // shrug
        return;
      }
      if (!serviceVersion) {
        console.log("Starting up with service version:", clientVersion);
        setServiceVersion(clientVersion);
      } else if (!_serviceVersionsCompatible(serviceVersion, clientVersion)) {
        console.log(`Reloading to match service version: ${clientVersion} -> ${serviceVersion}`);
        window.location.reload();
      }
      // otherwise we have a spurious reminder of the service version that we ignore.
    });
  }, [firebase, serviceVersion, setServiceVersion, user]);

  return <></>;
}
