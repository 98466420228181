import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Footer from "./components/Footer";
import Titlebar from "./components/Titlebar";
import UserServices from "./components/UserServices";
import MyGames from "./MyGames";
import NewSquaresGame from "./pages/NewSquaresGame";
import NewPickemGame from "./pages/NewPickemGame";
import UserPrefsDialog from "./components/UserPrefsDialog";
import FinishEmailSignIn from "./pages/FinishEmailSignIn";
import JoinGame from "./pages/JoinGame";
import ShowEntry from "./pages/ShowEntry";
import Game from "./pages/Game";
import GameContainer from "./components/GameContainer";
import HowToPlayPickem from "./pages/how-to-play/HowToPlay";

// Component to manage canonical tag
function CanonicalTag() {
  const location = useLocation();
  const [canonicalUrl, setCanonicalUrl] = useState<string | undefined>();

  useEffect(() => {
    const url = `${window.location.origin}${location.pathname}`;
    setCanonicalUrl(url);
  }, [location.pathname]);

  return canonicalUrl ? (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  ) : null;
}

export default function App() {
  const [serviceVersion, setServiceVersion] = useState<string | undefined>();
  const [showUserPrefs, setShowUserPrefs] = useState(false);

  return (
    <HelmetProvider>
      <CanonicalTag />
      <UserServices serviceVersion={serviceVersion} setServiceVersion={setServiceVersion} />
      <Titlebar serviceVersion={serviceVersion} />
      <Box component="main" width="100%" sx={{ userSelect: "none" }}>
        <Box width="100%" padding={2} marginBottom={4}>
          <Toolbar />

          <Routes>
            <Route index element={<MyGames />} />

            <Route path="/football-pools/pickem/online/new" element={<NewPickemGame />} />
            <Route path="/football-pools/pickem/online/new/slate/:teamSlate" element={<NewPickemGame />} />

            <Route path="/football-pools/squares/online/new" element={<NewSquaresGame />} />
            <Route path="/football-pools/squares/online/new/special/:specialId" element={<NewSquaresGame />} />
            <Route path="/football-pools/squares/online/new/league/:leagueId" element={<NewSquaresGame />} />
            <Route path="/football-pools/squares/online/new/slate/:teamSlate" element={<NewSquaresGame />} />

            <Route path="/football-pools/pickem/online/:gameId/*" element={<GameContainer />}>
              <Route index element={<Game />} />
              <Route path="entries/:entryUid" element={<ShowEntry />} />
              <Route path="join" element={<JoinGame />} />
            </Route>

            <Route path="/football-pools/squares/online/:gameId/*" element={<GameContainer />}>
              <Route index element={<Game />} />
              <Route path="join" element={<JoinGame />} />
            </Route>

            <Route path="/finish-email-sign-in" element={<FinishEmailSignIn />} />

            {/* legacy redirects */}
            <Route path="/create/pickem-pool" element={<NewPickemGame />} />
            <Route path="/create/squares-game" element={<NewSquaresGame />} />

            <Route path="/games/:gameId/*" element={<GameContainer />}>
              <Route index element={<Game />} />
              <Route path="entries/:entryUid" element={<ShowEntry />} />
              <Route path="join" element={<JoinGame />} />
            </Route>

            <Route path="/football-pools/pickem/how-to-play" element={<HowToPlayPickem />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          <UserPrefsDialog open={showUserPrefs} closeDialog={() => setShowUserPrefs(false)} />
        </Box>
        <Footer />
      </Box>
    </HelmetProvider>
  );
}
