import { useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

export function CopyToClipboardButton({ label, buffer }: { label: string; buffer: string }) {
  const [open, setOpen] = useState(false);
  function handleClick() {
    setOpen(true);
    navigator.clipboard.writeText(buffer).catch(alert);
  }

  return (
    <Stack direction="row" justifyContent="center">
      <Button variant="contained" onClick={handleClick}>
        {label}
      </Button>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="Copied to clipboard" />
    </Stack>
  );
}
