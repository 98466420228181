import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function LeftRightButtons({
  slugs,
  currentSlug,
  slugToPath,
}: {
  slugs: string[];
  currentSlug: string;
  // eslint-disable-next-line no-unused-vars
  slugToPath: (slug: string) => string;
}) {
  const navigate = useNavigate();

  if (slugs.length < 2) {
    return null;
  }
  let leftNavSlug: string | undefined;
  let rightNavSlug: string | undefined;
  const slugIndex = slugs.findIndex((slug) => slug === currentSlug);
  if (slugIndex !== -1) {
    leftNavSlug = slugs[(slugIndex + slugs.length - 1) % slugs.length];
    rightNavSlug = slugs[(slugIndex + 1) % slugs.length];
  }
  return (
    <>
      {leftNavSlug && (
        <IconButton onClick={() => navigate(slugToPath(leftNavSlug), { replace: true })}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {rightNavSlug && (
        <IconButton onClick={() => navigate(slugToPath(rightNavSlug), { replace: true })}>
          <ArrowForwardIcon />
        </IconButton>
      )}
    </>
  );
}
